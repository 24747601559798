import React from 'react';

import Button from '@mui/material/Button';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import { styled } from '@mui/material/styles';

import { DetailsViewTitle } from 'client/app/components/Parameters/Policy/components/DialogTitle';
import { LiquidPolicy } from 'client/app/gql';
import Colors from 'common/ui/Colors';

export default function PreviewPolicyDialog({
  policy,
  onClose,
  children,
}: Pick<DialogProps, 'onClose' | 'children'> & { policy: LiquidPolicy }) {
  return (
    <Dialog open fullWidth maxWidth="lg" onClose={onClose}>
      <DialogTitle>
        <DetailsViewTitle
          name={policy.name}
          author={policy.createdBy?.displayName ?? 'Synthace'}
          createDate={policy.createdAt}
        />
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          onClick={event => {
            onClose?.(event, 'escapeKeyDown');
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const DialogTitle = styled(MuiDialogTitle)({
  backgroundColor: Colors.GREY_5,
  borderBottom: `1px solid ${Colors.GREY_30}`,
});

const DialogContent = styled(MuiDialogContent)({
  display: 'flex',
  padding: 0,
  backgroundColor: Colors.GREY_5,
});

const DialogActions = styled(MuiDialogActions)({
  backgroundColor: Colors.GREY_5,
});
