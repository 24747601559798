import React, { useState } from 'react';

import CalendarToday from '@mui/icons-material/CalendarToday';
import PersonOutline from '@mui/icons-material/PersonOutline';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import ExampleSimulation from 'client/app/apps/protocols/ExampleSimulation';
import { useCurrentEntity } from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import { ProtocolInstanceQuery } from 'client/app/gql';
import { formatDateTime } from 'common/lib/format';
import Button from 'common/ui/components/Button';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';

type Protocol = ProtocolInstanceQuery['protocolInstance']['instance']['protocol'];

export default function ProtocolInfo({ protocol }: { protocol: Protocol }) {
  const { toggleShowInfo } = useCurrentEntity();

  const hasDescription = protocol.protocol.displayDescription !== '';
  const [isMixPreviewFullscreen, setIsMixPreviewFullcreen] = useState(false);

  return (
    <Wrapper isMixPreviewFullscreen={isMixPreviewFullscreen}>
      <Content>
        <Header>
          <Typography variant="h2">Protocol Details</Typography>
          <Button
            variant="tertiary"
            startIcon={<VisibilityOff />}
            onClick={toggleShowInfo}
          >
            Hide
          </Button>
        </Header>
        <MetadataFields>
          <Typography variant="subtitle2">Creator</Typography>
          <MetadataValue variant="body2">
            <PersonOutline fontSize="small" />
            {protocol.createdBy.displayName}
          </MetadataValue>
          <Typography variant="subtitle2">Created On</Typography>
          <MetadataValue variant="body2">
            <CalendarToday fontSize="small" />
            {formatDateTime(new Date(protocol.createdAt), 'at')}
          </MetadataValue>
        </MetadataFields>
        <ExampleSimulation
          simulation={protocol.exampleSimulation ?? undefined}
          setIsMixPreviewFullcreen={setIsMixPreviewFullcreen}
        />
        {hasDescription && (
          <Description>
            <Typography variant="subtitle2">Description</Typography>
            <MarkdownPreview markdown={protocol.protocol.displayDescription} />
          </Description>
        )}
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled(Paper)<{ isMixPreviewFullscreen: boolean }>(
  ({ theme, isMixPreviewFullscreen }) => ({
    padding: theme.spacing(6),
    inset: 0,
    zIndex: theme.zIndex.drawer,
    position: 'absolute',
    overflow: isMixPreviewFullscreen ? 'hidden' : 'auto',
  }),
);

const Content = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: 'auto / minmax(0, 580px)',
  justifyContent: 'center',
  gap: theme.spacing(5),
}));

const Header = styled('header')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const MetadataFields = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplate: 'auto auto / max-content',
  gridAutoFlow: 'column',
  rowGap: theme.spacing(2),
  columnGap: theme.spacing(6),
}));

const MetadataValue = styled(Typography)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

const Description = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});
