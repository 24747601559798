import React, { useCallback } from 'react';

import InputIcon from '@mui/icons-material/Input';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import ElementParameterFactorSwitch from 'client/app/components/Parameters/ElementParameterFactorSwitch';
import ParameterHeader from 'client/app/components/Parameters/ElementParameterHeader';
import { getParameterDisplayName } from 'client/app/lib/workflow/elementConfigUtils';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import { Parameter } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  elementId: string;
  parameter: Parameter;
  checked: boolean;
  disabled: boolean;
  message: string;
  onToggle: (checked: boolean) => void;
};

export function ProtocolItem({
  elementId,
  parameter,
  checked,
  disabled,
  message,
  onToggle,
}: Props) {
  const isElementConfigDebugModeEnabled = useFeatureToggle(
    'ELEMENT_CONFIGURATION_DEBUG_MODE',
  );
  const displayName = getParameterDisplayName(parameter, isElementConfigDebugModeEnabled);

  const handleToggle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onToggle(e.target.checked);
    },
    [onToggle],
  );

  return (
    <div>
      <StyledParameterHeader
        elementId={elementId}
        name={parameter.name}
        displayName={displayName}
        isRequired={false}
      />

      <StyledStack direction="row">
        <ElementParameterFactorSwitch
          checked={checked}
          onChange={handleToggle}
          disabled={disabled}
          label={<StyledInputIcon checked={checked} />}
        />

        {message && <HelperText>{message}</HelperText>}
      </StyledStack>
    </div>
  );
}

const StyledParameterHeader = styled(ParameterHeader)(({ theme: { spacing } }) => ({
  marginTop: 0,
  marginBottom: spacing(2),
}));

type StyledInputIconProps = {
  checked?: boolean;
};

const StyledInputIcon = styled(InputIcon, {
  shouldForwardProp: prop => prop !== 'disabled',
})<StyledInputIconProps>(({ checked }) => ({
  color: checked ? Colors.BACKGROUND : Colors.TEXT_PRIMARY,
}));

const HelperText = styled(TypographyWithTooltip)(({ theme }) => ({
  ...theme.typography.caption,
  padding: theme.spacing(3, 4),
  height: '32px',
  width: '100%',
  background: Colors.GREY_10,
  borderRadius: theme.spacing(2),
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  alignItems: 'center',
  gap: theme.spacing(4),
  height: '32px',
}));
