import { useState } from 'react';

import { LiquidPolicy, LiquidPolicySourceEnum } from 'client/app/gql';

export function useCreatorFilter() {
  const [state, setState] = useState([
    {
      selected: false,
      label: 'Synthace',
      value: LiquidPolicySourceEnum.system,
    },
    {
      selected: false,
      label: 'User',
      value: LiquidPolicySourceEnum.user,
    },
  ]);
  return { value: state, onChange: setState };
}

export type CreatorFilterType = ReturnType<typeof useCreatorFilter>;

export const bySearchQuery = (searchQuery?: string) => (policy: LiquidPolicy) => {
  const query = searchQuery?.toLowerCase().trim();
  return (
    !query ||
    policy.name.toLowerCase().includes(query) ||
    policy.description?.toLowerCase().includes(query)
  );
};

export const byCreator = (creatorFilter?: CreatorFilterType['value']) => {
  const policySourceOptions = creatorFilter
    ?.filter(option => option.selected)
    .map(option => option.value);

  const noOptionChecked = !policySourceOptions?.length;

  return (policy: LiquidPolicy) => {
    if (noOptionChecked) {
      return true; // no filter
    }

    let showPolicy: boolean = false;
    if (policySourceOptions.includes(LiquidPolicySourceEnum.user)) {
      showPolicy = showPolicy || !!policy.createdBy;
    }
    if (policySourceOptions.includes(LiquidPolicySourceEnum.system)) {
      const isCustomerSuccessPolicy =
        policy.source === LiquidPolicySourceEnum.user && !policy.createdBy;
      const isSystemPolicy = policy.source === LiquidPolicySourceEnum.system;

      showPolicy = showPolicy || isCustomerSuccessPolicy || isSystemPolicy;
    }

    return showPolicy;
  };
};
