import React from 'react';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import cx from 'classnames';

import Colors from 'common/ui/Colors';
import IconButton from 'common/ui/components/IconButton';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';

export type ComplexActionProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  /** If specified, will add these components as a fixed footer of the dialog */
  dialogActions?: React.ReactNode;
  /** If specified, will add a close icon button at top right of dialog */
  showCloseButton?: boolean;
  /** Adds a back click action (will render out an ArrowBackIcon button with this function attached) */
  onBackClick?: () => void;
  /** Overrides the className of the Dialog itself */
  className?: string;
  /** Overrides the className of the Paper component within Dialog */
  paperClassName?: string;
  /** Specify the max width of the dialog */
  maxWidth?: 'sm' | 'lg';
  /** Fill the height of the window */
  fullHeight?: boolean;
  noActionsDivider?: boolean;
  /**
   * Prevent restoring focus to previously focused element when dialog is closed.
   */
  disableRestoreFocus?: boolean;
};

/**
 * Complex Action Dialog is designed to handle scenarios where the dialog content has a lot of
 * interactivity (e.g. contains Cards that has filter actions)
 *
 * <ComplexActionDialog
 *   title="Simulations"
 *   content={<ContainerWithIntersectionBar .../>}
 *   dialogActions={<DialogActions> {actions} </DialogActions>}
 *   showCloseButton
 *   isOpen={isOpen}
 *   onClose={handleClose}
 *   onBackClick={handleBackClick}
 *   className={classes.dialog}
 *  />
 *
 * The content itself should be styled prior to passing in to this component, as no styling is
 * applied here. A <DialogContent> from MUI can be used but be aware this does apply some padding
 * so it might look a bit odd, so you might need to remove the padding.
 */

export default function ComplexActionDialog(props: ComplexActionProps) {
  const classes = useStyles();
  const leftAlignTitle = cx({ [classes.leftAlignTitle]: props.onBackClick });
  return (
    <Dialog
      className={props.className}
      classes={{
        paper: cx(props.paperClassName, { [classes.dialogFullHeight]: props.fullHeight }),
      }}
      open={props.isOpen}
      onClose={props.onClose}
      maxWidth={props.maxWidth || 'lg'}
      fullWidth
      onWheel={e => {
        e.stopPropagation();
      }}
      disableRestoreFocus={props.disableRestoreFocus}
    >
      <MuiDialogTitle className={classes.dialogTitle}>
        {props.onBackClick && (
          <IconButton
            icon={<ArrowBackIcon />}
            onClick={props.onBackClick}
            color="inherit"
            size="medium"
          />
        )}
        {typeof props.title === 'string' ? (
          <Typography className={leftAlignTitle} variant="h2" component="span">
            {props.title}
          </Typography>
        ) : (
          props.title
        )}
        {props.showCloseButton && (
          <IconButton
            icon={<CloseIcon />}
            onClick={props.onClose}
            color="inherit"
            size="small"
          />
        )}
      </MuiDialogTitle>
      <Divider />
      {props.content}
      {props.dialogActions && (
        // TODO: Follow up with consistent styling for this Dialog Action.
        <>
          {!props.noActionsDivider && <Divider />} {props.dialogActions}
        </>
      )}
    </Dialog>
  );
}

const useStyles = makeStylesHook(theme => ({
  dialogTitle: {
    backgroundColor: Colors.GREY_5,
    padding: theme.spacing(0, 6),
    height: '56px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftAlignTitle: {
    // If the back button is present, push the title to the left right next to
    // the back button to override the justifyContent: 'space-between' applied to the dialogTitle
    marginRight: 'auto',
  },
  dialogFullHeight: {
    alignSelf: 'stretch',
  },
}));
