import { graphql } from 'client/app/gql';

// NOTE: Apollo's caching layer relies on an `id` field being available, and
// throws a hissy fit if an object is fetched sometimes with the ID and other
// times without.  See
// https://www.apollographql.com/docs/react/caching/cache-configuration/#data-normalization.
//
// For this reason it is recommended to fetch the `id` field of everything that
// has one.  T2659 is an example of what can happen when we fail to do this.

export const Fragments = {
  // All device fields that we usually need to render a component or do some application logic
  DeviceCommon: graphql(/* GraphQL */ `
    fragment DeviceCommon on Device {
      id
      anthaHubGUID
      datastoreID
      runConfigSummaries {
        id
        name
        version
      }
      name
      model {
        id
        name
        anthaLangDeviceClass
        anthaClass
        anthaModel
        pictureURL
        series {
          id
          name
          manufacturer {
            id
            name
          }
          category {
            id
            name
            features
          }
        }
      }
      accessibleDevices {
        id
        name
        model {
          id
          name
          anthaLangDeviceClass
          anthaModel
          pictureURL
          series {
            id
            name
            manufacturer {
              id
              name
            }
          }
        }
        contentSource
      }
      contentSource
    }
  `),
  DeviceThumbnail: graphql(/* GraphQL */ `
    fragment DeviceThumbnail on Device {
      id
      name
      model {
        name
        anthaLangDeviceClass
        pictureURL
        series {
          manufacturer {
            name
          }
        }
      }
    }
  `),
  ElementSetCommon: graphql(/* GraphQL */ `
    fragment ElementSetCommon on ElementSet {
      id
      name
      workflowSchemaVersion
      isRelease
    }
  `),
  TemplateWorkflowCommon: graphql(/* GraphQL */ `
    fragment TemplateWorkflowCommon on TemplateWorkflow {
      id
      name
      description
      isShared
      isEditable
      createdAt
      createdBy {
        id
        displayName
      }
      lastModifiedAt
    }
  `),
  PageInfo: graphql(/* GraphQL */ `
    fragment PageInfoFragment on PageInfo {
      endCursor
      hasNextPage
    }
  `),
  ElementCommon: graphql(/* GraphQL */ `
    fragment ElementCommon on Element {
      id
      name
      description
      inputs {
        name
        description
        type
        groupName
        groupDescription
        configuration
      }
      outputs {
        name
        description
        type
        groupName
        configuration
      }
      configuration
      defaultParameters
      elementSetId
      isDeprecated
      sourceCodeURL
      tags
      releaseQuality
    }
  `),
  ExecutionListItem: graphql(/* GraphQL */ `
    fragment ExecutionListItem on Execution {
      id
      lastModifiedAt
      status
      user {
        id
        displayName
      }
      simulation {
        id
        timeEstimateSeconds
        name
      }
      hasDatasets
    }
  `),
  SimulationsForWorkflow: graphql(/* GraphQL */ `
    fragment SimulationListItem on Simulation {
      id
      workflow {
        id
      }
      user {
        id
        displayName
      }
      name
      startedAt
      lastUpdatedAt
      status
      favoritedBy
      isFavoritedByCurrentUser
      errors
      logsUrl
      traceUrl
      execution {
        id
      }
      isSharedExternally
      simulationSeriesPart
    }
  `),
  PlateType: graphql(/* GraphQL */ `
    fragment PlateTypeFragment on PlateType {
      id
      type
      name
      columns
      rows
      usage
      accessory
      manufacturer
      catalogNumber
      catalogUrl
      color
      description
      dimension
      format
      wellStart
      wellOffset
      wellBottomOffset
      defaultResidualVolume
      residualVolumes
      version
      wellShape {
        ...WellShapeFragment
      }
      isOrganisationSpecific
      containerType
      contentSource
    }
  `),
  WellShape: graphql(/* GraphQL */ `
    fragment WellShapeFragment on WellShape {
      type
      bottomType
      volumeOverrideUl
      dimensionMm
    }
  `),
  WorkflowCard: graphql(/* GraphQL */ `
    fragment WorkflowCardFragment on Workflow {
      name
      id
      source
      lastModifiedAt
      editMode
      createdBy {
        displayName
      }
      starredSimulationCount
      allSimulationCount
      contentSource
      hasDOEDesign
    }
  `),
  WorkflowFragment: graphql(/* GraphQL */ `
    fragment WorkflowFragment on Workflow {
      id
      version
      name
      workflow
      parentWorkflowID
      editMode
      source
      createdBy {
        id
        displayName
      }
      contentSource
    }
  `),
  Simulation: graphql(/* GraphQL */ `
    fragment Simulation on Simulation {
      id
      name
      workflow {
        id
        name
        workflow
        source
        version
      }
      editableWorkflow {
        id
        version
        editMode
      }
      user {
        id
        displayName
      }
      status
      transitiveStatus
      startedAt
      finishedAt
      timeEstimateSeconds
      tipUsage
      filetreeLink
      actionsLayoutFiletreeLink
      reagents {
        name
        volumeUl
        solutes {
          name
          concentration {
            unit
            value
          }
        }
        tags {
          label
          valueString
          valueFloat
        }
      }
      tasks @include(if: $withTasks) {
        id
        type
        filetreeLink
        orderNum
        payload
        device {
          id
          model {
            anthaLangDeviceClass
          }
        }
      }
      execution {
        id
      }
      visualisations @include(if: $withVisualisations) {
        id
        name
        path
        availability
      }
      internalSimulationInputPath
      errors
      logsUrl
      traceUrl
      isFavoritedByCurrentUser
      favoritedBy
      instructionsDownloaded
      source
      simulationSeriesPart
      simulationSeriesSiblings {
        id
        simulationSeriesPart
      }
      stages {
        ...SimulationStage
      }
    }
  `),
  SimulationStage: graphql(/* GraphQL */ `
    fragment SimulationStage on SimulationStage {
      id
      name
      orderNum
      mainDevice {
        ...DeviceThumbnail
        runConfigs {
          id
          name
        }
      }
      peripheralDevices {
        ...DeviceThumbnail
      }
    }
  `),
  /**
   * TODO: remove simulation dependency from Execution fragment as it is only
   * a compatibility with the old Execution page
   */
  Execution: graphql(/* GraphQL */ `
    fragment Execution on Execution {
      id
      createdAt
      finishedAt
      simulation {
        ...Simulation
      }
      stages {
        ...ExecutionStage
      }
      labwareHistory {
        id
        name
        ordering
        historyType
        datasets {
          originFile {
            id
            originFiletreeLink
            upload {
              id
            }
          }
          parsedFiletreeLink
          deviceName
        }
        properties {
          ... on LabwareHistoryAnalyticProperties {
            kind
          }
          ... on LabwareHistoryMappedLayoutProperties {
            plateName
            labwareFormat
            contents {
              sourceLabwareHistoryId
              location {
                row
                column
              }
              sourceLocation {
                row
                column
              }
            }
          }
        }
        labware {
          id
          barcode
        }
      }
      uploads {
        id
        state
        dataFiles {
          items {
            id
            state
            error
            originFiletreeLink
          }
        }
      }
    }
  `),
  ExecutionStage: graphql(/* GraphQL */ `
    fragment ExecutionStage on ExecutionStage {
      id
      status
      scheduledAt
      lastModifiedAt
      instructionsDownloaded
      comment
      simulationStage {
        id
        name
        simulationId
        orderNum
      }
      tasks {
        ...ExecutionTask
      }
      deviceExecutionMode {
        mode
        mainDevice {
          ...DeviceCommon
        }
        peripheralDevices {
          ...DeviceCommon
        }
      }
    }
  `),
  ExecutionTask: graphql(/* GraphQL */ `
    fragment ExecutionTask on ExecutionTask {
      id
      status
      driverMessage
      lastModifiedAt
      metadataFiles {
        name
        filetreeLink
        tags
        fileType
        sizeBytes
      }
      simulationTask {
        id
        name
        filetreeLink
        orderNum
        isInternal
        device {
          id
          name
          model {
            anthaLangDeviceClass
          }
        }
      }
    }
  `),
  BreadcrumbsStep: graphql(/* GraphQL */ `
    fragment BreadcrumbsStep on BreadcrumbsStep {
      entity
      items {
        id
        name
        createdAt
        createdBy {
          id
          firstName
          lastName
          email
        }
        isEditable
        seriesPart
        status
        url
        appName
        appStage
        isInput
      }
      currentItemID
    }
  `),
  LiquidPolicy: graphql(/* GraphQL */ `
    fragment LiquidPolicy on LiquidPolicy {
      id
      name
      source
      description
      rules {
        ...LiquidPolicyRule
      }
    }
  `),
  LiquidPolicyRule: graphql(/* GraphQL */ `
    fragment LiquidPolicyRule on LiquidPolicyRule {
      id
      ruleData
      ruleOrder
    }
  `),
};

export const QUERY_ELEMENT_BY_ID = graphql(/* GraphQL */ `
  query Element($id: String!) {
    element(id: $id) {
      ...ElementCommon
    }
  }
`);

export const QUERY_RECENTLY_ACCESSED_ELEMENT_SETS = graphql(/* GraphQL */ `
  query RecentlyAccessedElementSets {
    loggedInUser {
      id
      recentlyAccessed {
        elementSets {
          legacyId
        }
      }
    }
  }
`);

export const QUERY_ELEMENT_SETS = graphql(/* GraphQL */ `
  query ElementSets {
    latestElementSets {
      ...ElementSetCommon
    }
  }
`);

export const QUERY_ELEMENT_SET_WITH_ELEMENTS = graphql(/* GraphQL */ `
  query ElementSet($id: String!) {
    elementSet(id: $id) {
      ...ElementSetCommon
      elements {
        ...ElementCommon
      }
    }
  }
`);

export const QUERY_ELEMENT_SET_ELEMENT_CONFIGURATIONS = graphql(/* GraphQL */ `
  query ConfigurationsPerElementSet($id: String!) {
    elementSet(id: $id) {
      elements {
        name
        configuration
      }
    }
  }
`);

export const QUERY_DEVICE_BY_ID_FOR_RUN_CONFIG = graphql(/* GraphQL */ `
  query DeviceByIdForRunConfig($id: DeviceId!) {
    device(id: $id) {
      id
      runConfigSummaries {
        id
        name
        version
        lastModifiedAt
      }
    }
  }
`);

export const QUERY_DEVICE_CONFIG_RESPONSE = graphql(/* GraphQL */ `
  query DeviceParsedRunConfig($id: String!, $runConfigId: String) {
    parsedRunConfig(deviceId: $id, configId: $runConfigId) {
      id
      config
    }
  }
`);

export const QUERY_PARSED_DECK_LAYOUT_CONFIG_LIST = graphql(/* GraphQL */ `
  query ParsedDeckLayoutConfigList(
    $deviceId: String!
    $deviceConfig: DeviceConfigData
    $runConfigs: [DeviceRunConfigData!]!
  ) {
    parsedDeckLayoutConfigList(
      deviceId: $deviceId
      deviceConfig: $deviceConfig
      runConfigs: $runConfigs
    ) {
      label
      valid
      deck
      error {
        code
        details
        message
        messageType
      }
    }
  }
`);

export const QUERY_DEVICE_WITH_CONFIG_BY_ID = graphql(/* GraphQL */ `
  query DeviceWithConfigById($anthaHubGUID: String!) {
    devices(anthaHubGUID: $anthaHubGUID) {
      id
      anthaHubGUID
      datastoreID
      instanceConfig {
        id
        lastModifiedAt
        config
      }
      runConfigs {
        id
        name
        summary
        config
      }
    }
  }
`);

export const QUERY_INSTANCE_CONFIG_BY_DEVICE_ID = graphql(/* GraphQL */ `
  query DeviceInstanceConfigByDeviceId($id: DeviceId!) {
    devices(id: $id) {
      name
      instanceConfig {
        config
      }
    }
  }
`);

export const QUERY_ALL_DEVICES = graphql(/* GraphQL */ `
  query AllDevices {
    devices {
      ...DeviceCommon
    }
  }
`);

export const QUERY_DEVICES_WITH_USER_EXECUTIONS = graphql(/* GraphQL */ `
  query DevicesWithUserExecutions($userId: String) {
    devices(withExecutionsBy: $userId) {
      ...DeviceCommon
    }
  }
`);

/**
 * Devices which can be selected as accessible by another device.
 * As of Jul 2020 these are analytic devices (mainly plate readers)
 * and simple serial devices (mainly plate washers).
 */
export const QUERY_DEVICES_SELECTABLE_AS_ACCESSIBLE = graphql(/* GraphQL */ `
  query DevicesSelectableAsAccessible {
    devicesSelectableAsAccessible {
      ...DeviceCommon
    }
  }
`);

/**
 * Query a single entry in the table accessible_devices.
 */
export const QUERY_ACCESSIBLE_DEVICE = graphql(/* GraphQL */ `
  query AccessibleDevice($parentDeviceId: UUID!, $accessibleDeviceId: UUID!) {
    accessibleDevice(
      parentDeviceId: $parentDeviceId
      accessibleDeviceId: $accessibleDeviceId
    ) {
      accessibleDeviceSetup
    }
  }
`);

export const QUERY_DEVICE_PLATE_MATCHES = graphql(/*GraphQL */ `
  query PlateMatches($deviceConfig: DeviceConfigData!, $anthaHubGUID: String!) {
    plateMatch(deviceConfig: $deviceConfig, anthaHubGUID: $anthaHubGUID) {
      oneToOneMatches {
        synthacePlate {
          labwareName
          plateType
          manufacturer
          partNumber
        }
        vendorPlate {
          labwareName
          category
          manufacturer
          partNumbers
        }
      }
      oneToManyMatches {
        synthacePlates {
          labwareName
          plateType
          manufacturer
          partNumber
        }
        vendorPlate {
          labwareName
          category
          manufacturer
          partNumbers
        }
      }
      nonMatches {
        vendorPlate {
          labwareName
          category
          manufacturer
          partNumbers
        }
      }
    }
  }
`);

export const QUERY_DEVICE_LIQUID_CLASSES_COUNT = graphql(/* GraphQL */ `
  query DeviceLiquidClassesCount($anthaHubGUID: String!) {
    devices(anthaHubGUID: $anthaHubGUID) {
      id
      liquidClasses {
        count
      }
    }
  }
`);

export const QUERY_DEVICE_LIQUID_CLASSES_CSV_BY_GUID = graphql(/* GraphQL */ `
  query DeviceLiquidClassesCSVByGUID($anthaHubGUID: String!) {
    devices(anthaHubGUID: $anthaHubGUID) {
      id
      liquidClasses {
        asCSV
      }
    }
  }
`);

export const QUERY_GET_MISSING_DEVICE_LIQUID_CLASSES = graphql(/* GraphQL */ `
  query MissingDeviceLiquidClasses($anthaHubGUID: String!) {
    devices(anthaHubGUID: $anthaHubGUID) {
      id
      liquidClasses {
        missing
      }
    }
  }
`);

export const QUERY_MISSING_DEVICE_LIQUID_CLASSES_AS_CSV = graphql(/* GraphQL */ `
  query MissingDeviceLiquidClassesAsCSV($anthaHubGUID: String!) {
    devices(anthaHubGUID: $anthaHubGUID) {
      id
      liquidClasses {
        missingAsCSVTemplate
      }
    }
  }
`);

export const QUERY_WORKFLOW_BY_ID = graphql(/* GraphQL */ `
  query WorkflowById($id: WorkflowId!) {
    workflow(id: $id) {
      ...WorkflowFragment
    }
  }
`);

export const QUERY_SIMULATION_COUNTS_BY_WORKFLOW_ID = graphql(/* GraphQL */ `
  query SimulationCountByWorkflowId($id: WorkflowId!) {
    workflow(id: $id) {
      id
      allSimulationCount
      starredSimulationCount
    }
  }
`);

export const QUERY_WORKFLOW_BY_ID_WITH_SIMULATIONS = graphql(/* GraphQL */ `
  query WorkflowWithSim(
    $workflowId: WorkflowId!
    $userId: String
    $favorited: FavoritedByFilterEnum
    $filterSimulationStatus: String
    $filterStartDate: DateTime
    $filterEndDate: DateTime
    $search: String
  ) {
    workflow(id: $workflowId) {
      ...WorkflowCardFragment
    }
    simulationsForWorkflow(
      workflowId: $workflowId
      userId: $userId
      favorited: $favorited
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      statusToFilter: $filterSimulationStatus
      search: $search
    ) {
      items {
        ...SimulationListItem
      }
    }
  }
`);

export const QUERY_WORKFLOWS_BY_SIMULATIONS = graphql(/* GraphQL */ `
  query WorkflowsBySimulations(
    $after: String
    $userId: String
    $search: String
    $favorited: FavoritedByFilterEnum
    $filterStartDate: DateTime
    $filterEndDate: DateTime
    $workflowTypeFilter: [WorkflowSourceEnum!]
    $filterSimulationStatus: String
    $contentSource: ContentType
  ) {
    workflowsBySimulations(
      after: $after
      userId: $userId
      simulationFavorited: $favorited
      filterSimulationStartDate: $filterStartDate
      filterSimulationEndDate: $filterEndDate
      workflowTypeFilter: $workflowTypeFilter
      filterSimulationStatus: $filterSimulationStatus
      contentSource: $contentSource
      count: 10
      search: $search
    ) {
      items {
        ...WorkflowCardFragment
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_SIMULATIONS_FOR_WORKFLOW = graphql(/* GraphQL */ `
  query simulationsForWorkflow(
    $workflowId: WorkflowId!
    $userId: String
    $search: String
    $favorited: FavoritedByFilterEnum
    $filterStartDate: DateTime
    $filterEndDate: DateTime
    $filterSimulationStatus: String
    $after: String
  ) {
    simulationsForWorkflow(
      workflowId: $workflowId
      userId: $userId
      favorited: $favorited
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      statusToFilter: $filterSimulationStatus
      search: $search
      after: $after
    ) {
      items {
        ...SimulationListItem
        simulationSeriesSiblings {
          ...SimulationListItem
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_DOE_WORKFLOWS = graphql(/* GraphQL */ `
  query DOEWorkflows($userId: String, $search: String, $after: String) {
    workflows(
      workflowTypeFilter: [DOE_TEMPLATE_EDITOR]
      userId: $userId
      search: $search
      after: $after
    ) {
      items {
        id
        lastModifiedAt
        name
        createdBy {
          id
          displayName
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_TEMPLATE_WORKFLOWS = graphql(/* GraphQL */ `
  query TemplateWorkflows($search: String, $after: String) {
    templateWorkflows(search: $search, after: $after) {
      items {
        workflow {
          id
        }
        ...TemplateWorkflowCommon
      }
      pageInfo {
        ...PageInfoFragment
      }
      totalCount
    }
  }
`);

/**
 * Get the list of incoming workflows.
 */
export const QUERY_INCOMING_WORKFLOWS = graphql(/* GraphQL */ `
  query IncomingWorkflows($search: String!) {
    sharedWorkflows(search: $search) {
      id
      sourceOrg {
        name
      }
      org {
        name
      }
      sourceUser {
        id
        displayName
      }
      sourceWorkflowName
      sourceWorkflow {
        name
        source
      }
      createdAt
    }
  }
`);

/**
 * Get the list of incoming Methods.
 */
export const QUERY_INCOMING_METHODS = graphql(/* GraphQL */ `
  query IncomingMethods($search: String!) {
    sharedMethods(search: $search) {
      id
      sourceOrg {
        name
      }
      org {
        name
      }
      sourceMethodApplicationDisplayName
      sourceMethodDescription
      sourceMethodLastModifiedAt
      sourceUser {
        id
        displayName
      }
      sourceUserTimezone
      createdAt
      restoredMethods {
        id
        description
        createdBy {
          id
          displayName
        }
        createdAt
        lastModifiedAt
        isOwned
        href
      }
    }
  }
`);

export const QUERY_DRAFT_WORKFLOWS = graphql(/* GraphQL */ `
  query draftWorkflows(
    $userId: String
    $search: String
    $workflowTypeFilter: [WorkflowSourceEnum!]
    $after: String
  ) {
    draftWorkflows(
      search: $search
      userId: $userId
      workflowTypeFilter: $workflowTypeFilter
      after: $after
    ) {
      items {
        id
        name
        lastModifiedAt
        source
        editMode
        createdBy {
          displayName
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_SIMULATION = graphql(/* GraphQL */ `
  query Simulation(
    $id: SimulationId!
    $withTasks: Boolean! = false
    $withVisualisations: Boolean! = false
  ) {
    simulation(id: $id) {
      ...Simulation
    }
  }
`);
export const QUERY_SIMULATION_FROM_EXECUTION_ID = graphql(/* GraphQL */ `
  query SimulationFromExecutionId(
    $id: ExecutionId!
    $withTasks: Boolean! = false
    $withVisualisations: Boolean! = false
  ) {
    execution(id: $id) {
      simulation {
        ...Simulation
      }
    }
  }
`);

export const QUERY_EXECUTION_FILES = graphql(/* GraphQL */ `
  query ExecutionFiles($id: ExecutionId!) {
    execution(id: $id) {
      id
      simulation {
        id
        name
      }
      labwareHistory {
        id
        name
        ordering
        historyType
        datasets {
          originFile {
            id
            originFiletreeLink
          }
          deviceName
        }
        properties {
          ... on LabwareHistoryAnalyticProperties {
            kind
          }
        }
      }
    }
  }
`);

/**
 * Lightweight query for vis iframe
 */
export const QUERY_VIS_METADATA = graphql(/* GraphQL */ `
  query VisMetadata(
    $simulationId: SimulationId!
    $dataVisualisationId: DataVisualisationId!
  ) {
    visualisation(id: $dataVisualisationId) {
      name
    }
    simulation(id: $simulationId) {
      id
      name
      user {
        id
        displayName
      }
      status
      startedAt
      finishedAt
      timeEstimateSeconds
      execution {
        id
      }
    }
  }
`);

/**
 * A light query fetching a simulation's status.  This is used repeatedly
 * post-simulation so that the UI can update once the simulation has finished.
 */
export const QUERY_SIMULATION_POLL = graphql(/* GraphQL */ `
  query SimulationForPoll($id: SimulationId!) {
    simulation(id: $id) {
      id
      status
      errors
      logsUrl
      traceUrl
      actionsLayoutFiletreeLink
    }
  }
`);

export const QUERY_SIMULATION_CARD_POLL = graphql(/* GraphQL */ `
  query SimulationCardPoll($id: SimulationId!) {
    simulation(id: $id) {
      ...SimulationListItem
      simulationSeriesSiblings {
        ...SimulationListItem
      }
    }
  }
`);

/**
 * Lightweight query useful when UI has the simulation ID, but needs
 * to display the simulation name to users, e.g. in `SimulationParameter`.
 */
export const QUERY_SIMULATION_NAME = graphql(/* GraphQL */ `
  query SimulationNameOnly($id: SimulationId!) {
    simulation(id: $id) {
      id
      name
    }
  }
`);

/*
 * In the simulation dialog, we want to select an existing simulation to pick files, plates, etc.
 */
export const QUERY_SIMULATIONS_DIALOG = graphql(/* GraphQL */ `
  query SimulationsForDialog(
    $after: String
    $status: String
    $userId: String
    $filterStartDate: DateTime
    $filterEndDate: DateTime
    $favoriteToFilter: FavoritedByTypes
    $searchQuery: String
  ) {
    simulations(
      after: $after
      status: $status
      userId: $userId
      favoriteToFilter: $favoriteToFilter
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      searchQuery: $searchQuery
    ) {
      items {
        id
        name
        status
        lastUpdatedAt
        startedAt
        user {
          id
          displayName
        }
        isFavoritedByCurrentUser
        favoritedBy
        filetreeLink
        actionsLayoutFiletreeLink
        legacyJobId
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_EXECUTIONS = graphql(/* GraphQL */ `
  query Executions(
    $after: String
    $statusToFilter: [ExecutionStatusEnum]
    $userId: String
    $filterStartDate: DateTime
    $filterEndDate: DateTime
    $searchQuery: String
    $kinOf: String
    $deviceIds: [String]
    $hasDatasets: Boolean
  ) {
    executions(
      after: $after
      statusToFilter: $statusToFilter
      userId: $userId
      filterStartDate: $filterStartDate
      filterEndDate: $filterEndDate
      searchQuery: $searchQuery
      kinOf: $kinOf
      deviceIds: $deviceIds
      hasDatasets: $hasDatasets
    ) {
      items {
        ...ExecutionListItem
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_EXECUTION = graphql(/* GraphQL */ `
  query Execution(
    $id: ExecutionId!
    $withTasks: Boolean! = false
    $withVisualisations: Boolean! = false
  ) {
    execution(id: $id) {
      ...Execution
    }
  }
`);

export const QUERY_JOB_SIMULATION_REDIRECT = graphql(/* GraphQL */ `
  query JobSimulationRedirect($legacyJobId: String!) {
    jobSimulationRedirect(legacyJobId: $legacyJobId) {
      id
    }
  }
`);

export const QUERY_SIMULATION_NAME_BY_LEGACY_JOB_ID = graphql(/* GraphQL */ `
  query SimulationByLegacyJobId($id: String!) {
    simulationByLegacyJobId(legacyJobId: $id) {
      name
    }
  }
`);

export const QUERY_All_SHAREABLE_ORGS_AND_ORIGIN_ORG = graphql(/* GraphQL */ `
  query ShareableAndOriginOrgs($workflowId: WorkflowId!) {
    workflow(id: $workflowId) {
      shareOriginOrg {
        humanIdentifier
        name
        id
      }
    }
    shareableOrgs {
      humanIdentifier
      name
      id
    }
  }
`);

export const QUERY_UI_BOOTUP = graphql(/* GraphQL */ `
  query UIBootupQuery {
    uiBootup {
      intercom {
        appId
        token
      }
      loggedInUser {
        id
        legacyId
        email
        firstName
        lastName
        displayName
        isSynthaceEmployee
      }
      loggedInOrg {
        id
        name
        humanIdentifier
        isSupport
        isExampleWorkflowsSourceOrg
      }
    }
  }
`);
export const QUERY_ENTITIES_COUNT = graphql(/* GraphQL */ `
  query EntitiesCountQuery {
    entitiesCount {
      workflows
      doeWorkflows
      incomingWorkflows
      executions
    }
  }
`);
export const QUERY_FEATURE_TOGGLES = graphql(/* GraphQL */ `
  query FeatureTogglesQuery {
    featureToggles {
      name
      enabled
      description
      requiresReload
      kind
    }
  }
`);

export const QUERY_ORG_USERS = graphql(/* GraphQL */ `
  query Users {
    users(order: "ASC") {
      id
      displayName
    }
  }
`);

export const QUERY_DEFAULT_ELEMENT_SET = graphql(/* GraphQL */ `
  query DefaultElementSet {
    defaultElementSet {
      ...ElementSetCommon
      elements {
        ...ElementCommon
      }
    }
  }
`);

export const QUERY_PLATE_TYPE = graphql(/* GraphQL */ `
  query PlateType($plateType: String!) {
    plateType(type: $plateType) {
      ...PlateTypeFragment
    }
  }
`);

export const QUERY_LIST_ALL_PLATE_TYPES = graphql(/* GraphQL */ `
  query PlateTypes($deviceIds: [DeviceId!]) {
    plateTypes(deviceIds: $deviceIds) {
      plate {
        ...PlateTypeFragment
      }
      compatibility {
        input
        output
        accessibleDeviceCompatibilityMessage
      }
    }
  }
`);

export const QUERY_DATA_UPLOAD_STATE_POLL = graphql(/* GraphQL */ `
  query DataUploadStatePoll($uploadId: DataUploadId!) {
    upload(id: $uploadId) {
      id
      state
    }
  }
`);

export const QUERY_SYNTHETIC_DATA_GENERATOR = graphql(/* GraphQL */ `
  query SyntheticDataGenerator($deviceId: DeviceId!) {
    deviceDataGenerator(deviceId: $deviceId) {
      defaultParameters
    }
  }
`);

export const QUERY_WORKFLOW_DETAILS_PANEL = graphql(/* GraphQL */ `
  query WorkflowDetailsPanel($id: WorkflowId!) {
    workflow(id: $id) {
      id
      version
      createdBy {
        displayName
      }
      lastModifiedAt
      parentWorkflowID
    }
  }
`);

export const QUERY_USERS_ROLES = graphql(/* GraphQL */ `
  query userRoles {
    users(order: "ASC") {
      id
      displayName
      email
      role {
        id
        name
      }
    }
  }
`);

export const QUERY_AVAILABLE_ROLES = graphql(/* GraphQL */ `
  query availableRoles {
    roles {
      id
      name
    }
  }
`);

export const QUERY_BREADCRUMBS = graphql(/* GraphQL */ `
  query Breadcrumbs($id: String!, $entity: BreadcrumbsEntityEnum!) {
    breadcrumbs(id: $id, entity: $entity) {
      ...BreadcrumbsStep
    }
  }
`);

export const QUERY_EXAMPLE_WORKFLOWS = graphql(/* GraphQL */ `
  query ExampleWorkflows(
    $after: String
    $includeDeleted: Boolean
    $search: String
    $tagName: String
  ) {
    exampleWorkflows(
      after: $after
      includeDeleted: $includeDeleted
      search: $search
      tagName: $tagName
    ) {
      items {
        id
        name
        workflowId
        exampleWorkflowIdSourceEnv
        summary
        description
        createdBy {
          displayName
        }
        lastModifiedAt
        representativeSimulation {
          id
          editableWorkflow {
            id
          }
        }
        tags {
          id
          name
          createdAt
          createdByUserId
        }
      }
      pageInfo {
        ...PageInfoFragment
      }
    }
  }
`);

export const QUERY_EXAMPLE_WORKFLOW = graphql(/* GraphQL */ `
  query ExampleWorkflow($parentWorkflowId: WorkflowId) {
    exampleWorkflow(parentWorkflowId: $parentWorkflowId) {
      id
      workflowId
      name
      summary
      description
      tags {
        id
        name
      }
    }
  }
`);

export const QUERY_UNIQUE_TAGS = graphql(/* GraphQL */ `
  query UniqueTags($entityClassName: String) {
    uniqueTags(entityClassName: $entityClassName)
  }
`);

export const QUERY_WORKFLOW_STATUS_BREADCRUMBS = graphql(/* GraphQL */ `
  query WorkflowStatusBreadcrumbs($count: Int) {
    workflowStatusBreadcrumbs(count: $count) {
      workflow {
        id
        name
        source
      }
      lastUpdated
      steps {
        ...BreadcrumbsStep
      }
    }
  }
`);

export const QUERY_PROTOCOL = graphql(/* GraphQL */ `
  query Protocol($id: ProtocolId!, $version: ProtocolVersion!) {
    protocol(id: $id, version: $version) {
      entity {
        id
        version
        name
        shortDescription
        editVersion
        protocol
        workflow {
          ...WorkflowFragment
        }
        exampleSimulation {
          id
          status
          actionsLayoutFiletreeLink
        }
      }
      elementContext {
        elementContextMap
        elementContextError
      }
    }
  }
`);

export const QUERY_PROTOCOL_INSTANCE = graphql(/* GraphQL */ `
  query ProtocolInstance($id: ProtocolInstanceId!) {
    protocolInstance(id: $id) {
      instance {
        id
        name
        editVersion
        parameters
        protocol {
          id
          name
          protocol
          createdBy {
            displayName
          }
          createdAt
          workflow {
            ...WorkflowFragment
          }
          exampleSimulation {
            id
            status
            actionsLayoutFiletreeLink
          }
        }
      }
      elementContext {
        elementContextMap
        elementContextError
      }
    }
  }
`);

export const QUERY_LIQUID_POLICIES = graphql(/* GraphQL */ `
  query LiquidPolicies {
    liquidPolicies {
      id
      name
      source
      description
      createdAt
      createdBy {
        id
        displayName
      }
      rules {
        id
        ruleData
        ruleOrder
      }
    }
  }
`);
