import { useCallback, useMemo } from 'react';

import { ConfiguredDevice, WorkflowConfig } from 'common/types/bundle';
import { getGenericDeviceType } from 'common/types/bundleConfigUtils';

type Props = {
  workflowConfig: WorkflowConfig;
  /** Deprecated. DOETemplateMode is part of DOE_ALPHA, which is now superseded by NEW_DOE */
  DOETemplateMode?: boolean;
};

const useSimulationButton = ({ workflowConfig, DOETemplateMode = false }: Props) => {
  const requiresDevice = workflowConfig.global.requiresDevice;

  /**
   * If the device is Liquid handler and is not 'GilsonPipetMax' we check if it has the deck config attached.
   * If the deck config is missing we considered this not configured.
   * For all other devices we assume those are configured.
   */
  const deviceHasSelectedRunConfig = useCallback((device: ConfiguredDevice) => {
    const isLiquidHandler = getGenericDeviceType(device.type) === 'LiquidHandler';
    if (isLiquidHandler) {
      if (device.type === 'GilsonPipetMax') return true;
      return !!device.runConfigId;
    }
    return true;
  }, []);

  const allDevicesConfigured = useMemo(
    () => workflowConfig?.configuredDevices?.every(deviceHasSelectedRunConfig),
    [deviceHasSelectedRunConfig, workflowConfig?.configuredDevices],
  );

  const shouldDisableSimulateButton =
    !DOETemplateMode && requiresDevice && !allDevicesConfigured;

  return {
    shouldDisableSimulateButton,
    simulateButtonTooltipTitle: shouldDisableSimulateButton
      ? 'No deck layout has been selected for one of the devices'
      : '',
  };
};

export default useSimulationButton;
