export type Schema = {
  inputs?: SchemaInput[];
  outputs?: SchemaOutput[];
};

/**
 * returns a copy of schema with any inputs or outputs which refer to the element with
 * the given ID removed
 */
export function removeElementsFromSchema(
  schema: Schema,
  elementIds: Set<string>,
): Schema {
  const filter = function (p: Path): boolean {
    const eId = getElementId(p);
    return !eId || !elementIds.has(eId);
  };
  return {
    inputs: schema.inputs?.filter(i => filter(i.path)),
    outputs: schema.outputs?.filter(o => filter(o.path)),
  };
}

export type SchemaInput = {
  /**
   * identifier for this input, which is unique within the inputs of this workflow
   */
  id: string;
  /**
   * identifies the type of the input
   */
  typeName: string;
  /**
   * path is used to identify the location within the workflow where inputs should
   * be placed.
   */
  path: Path;
  /**
   * the default value for the input if no user input is provided
   */
  default?: any;
  /**
   * contextId groups related inputs by element in cases where input components require
   * knowledge of the values of other inputs within the same element
   */
  contextId?: string;
};

export type SchemaOutput = {
  /**
   * identifier for this output, which is unique within the outputs of this workflow
   */
  id: string;
  /**
   * identifies the type of the output, and thus how computed outputs should be displayed
   */
  typeName: string;
  /**
   * path is used internally to identify the location within the workflow where outputs are
   * read from. This is handled internally and so there should be no need for client code
   * to interact with this field directly.
   */
  path: Path;
};

export function byElementInstanceId(id: string) {
  return (item: SchemaInput | SchemaOutput) => {
    return getElementId(item.path) === id;
  };
}

/**
 * The Path type identifies a location within a workflow. It should not normally be necessary
 * inspect the value as reading and writing of values from the workflow is handled in antha-core.
 *
 * Note: currently only ElementPaths are supported, we expect to add other types (e.g. stage
 * and device configuration) in the future.
 */
export type Path = ElementPath;

export function getElementId(path: Path): string | undefined {
  if (path.length >= 3 && path[0] === 'element') {
    return path[1];
  }
  return;
}

export function getElementParameterName(path: Path): string | undefined {
  if (path.length >= 3 && path[0] === 'element') {
    return path[2];
  }
  return;
}

/**
 * Describes the path of an element input or output.
 */
export type ElementPath = [
  label: 'element',
  elementInstanceId: string,
  paramName: string,
  ...extraKeys: string[],
];

/**
 * creates a new ElementPath which can be read by antha-core.
 *
 * 'elementId' and 'parameterName' are required and identify the element and parameter
 * within the element (this may be an input or output).
 *
 * 'extraKeys' is relevant only for inputs, and allows for control of how the value
 * provided in parameter maps is to be written into the element input, effectively allowing
 * inputs to refer to values within complex types.
 *
 * For example, consider an input parameter whose JSON representation has the following
 * structure:
 *   type ExampleInput = {
 *     plateTypes: string[];
 *     wellCoords: string[];
 *   }
 * and an example value
 *   {
 *     "plateTypes": ["plateType1", "plateType2"],
 *     "wellCoords": ["A1", "A2"]
 *   }
 *
 * `extraKeys` may have a number of values, such as:
 *   * `extraKeys = undefined` or `extraKeys = []`
 *     * Expected Type: `ExampleInput`
 *     * Effect: The entire parameter is replaced by the value provided
 *   * `extraKeys = ["plateTypes"]`
 *     * Expected Type: `string[]`
 *     * Effect: the value of "plateTypes" is replaced by the value provided
 *   * `extraKeys = ["plateTypes", "1"]`
 *     * Expected Type = `string`
 *     * Effect: the value of `plateTypes[1]` (i.e. "plateType2") is replaced by the value provided
 *   * `extraKeys = ["plateTypes", "-1"]`
 *     * Expected Type = `string[]`
 *     * Effect: the values provided are appended to the existing array at `plateTypes`
 */
export function newElementPath(
  elementId: string,
  parameterName: string,
  extraKeys?: string[],
): ElementPath {
  return ['element', elementId, parameterName, ...(extraKeys || [])];
}

/**
 * ParameterMap provides values for the set of inputs or outputs defined in
 * the Schema section of a workflow
 */
export type ParameterMap = {
  [id: string]: any;
};
