import React, { createContext, FC, useContext, useMemo, useState } from 'react';

import { v4 as uuid } from 'uuid';

import { useProtocolsParamState } from 'client/app/apps/protocols/lib/utils';
import { Markdown } from 'common/lib/markdown';
import { Parameter } from 'common/types/bundle';
import { ParameterEditorConfigurationSpec } from 'common/types/commonConfiguration';
import {
  ProtocolStep,
  ProtocolStepInput,
  ProtocolStepOutput,
} from 'common/types/Protocol';
import {
  ElementPath,
  getElementId,
  getElementParameterName,
  newElementPath,
  Schema,
  SchemaInput,
  SchemaOutput,
} from 'common/types/schema';

/**
 * StepState represents the state of a step both in terms of presentation in
 * the protocol and function in the workflow schema. As such it is meant to be
 * the single source of truth for step information
 */
type StepState = {
  id: string;
  displayName: string;
  inputs: InputStepState[];
  outputs: OutputStepState[];
};

type InputStepState = {
  id: string;
  typeName: string;
  path: ElementPath;
  displayName: string;
  default?: any;
  contextId?: string;
  displayDescription?: Markdown;
  configuration?: ParameterEditorConfigurationSpec;
};

type OutputStepState = {
  id: string;
  typeName: string;
  path: ElementPath;
  displayName: string;
};

const newStepStates = (schema: Schema, steps: ProtocolStep[]) => {
  const temporaryPath = newElementPath('temporary', 'entry');

  // notice that we init with a temporary path, update based on schema and
  // remove any temporary paths afterwards. This means the schema is the source
  // of truth on re-initialisation
  const stepStates = steps.map<StepState>(step => {
    return {
      id: step.id,
      displayName: step.displayName,
      errors: [],
      inputs: step.inputs.map<InputStepState>(input => {
        return {
          id: input.id,
          path: temporaryPath,
          typeName: '',
          displayName: input.displayName,
          displayDescription: input.displayDescription,
          configuration: input.configuration,
        };
      }),
      outputs: step.outputs.map<OutputStepState>(output => {
        return {
          id: output.id,
          path: temporaryPath,
          typeName: '',
          displayName: output.displayName,
        };
      }),
    };
  });

  schema.inputs?.forEach(input => {
    stepStates.some(step => {
      const state = step.inputs.find(({ id }) => id === input.id);
      if (state) {
        state.path = input.path;
        state.typeName = input.typeName;
        state.default = input.default;
        state.contextId = input.contextId;
        return true;
      }
      return false;
    });
  });

  schema.outputs?.forEach(output => {
    stepStates.some(step => {
      const state = step.outputs.find(({ id }) => id === output.id);
      if (state) {
        state.path = output.path;
        state.typeName = output.typeName;
        return true;
      }
      return false;
    });
  });

  stepStates.forEach(step => {
    step.inputs = step.inputs.filter(({ path }) => path !== temporaryPath);
    step.outputs = step.outputs.filter(({ path }) => path !== temporaryPath);
  });

  return stepStates;
};

type StepStateUpdate = {
  input?: {
    add?: InputStepState;
    removeById?: string;
    removeByPath?: ElementPath;
  };
  output?: {
    add?: OutputStepState;
    removeById?: string;
    removeByPath?: ElementPath;
  };
};

const updateStepStates = (steps: StepState[], index: number, opts: StepStateUpdate) => {
  const step = steps[index];
  const update = { ...step };
  const { input, output } = opts;
  if (input) {
    const { add, removeById, removeByPath } = input;
    if (add) {
      update.inputs = [...update.inputs, add];
    }
    if (removeById) {
      update.inputs = update.inputs.filter(i => i.id !== removeById);
    }
    if (removeByPath) {
      update.inputs = update.inputs.filter(
        i =>
          getElementId(i.path) !== getElementId(removeByPath) ||
          getElementParameterName(i.path) !== getElementParameterName(removeByPath),
      );
    }
  }
  if (output) {
    const { add, removeById, removeByPath } = output;
    if (add) {
      update.outputs = [...update.outputs, add];
    }
    if (removeById) {
      update.outputs = update.outputs.filter(i => i.id !== removeById);
    }
    if (removeByPath) {
      update.outputs = update.outputs.filter(
        i =>
          getElementId(i.path) !== getElementId(removeByPath) ||
          getElementParameterName(i.path) !== getElementParameterName(removeByPath),
      );
    }
  }
  return [...steps.slice(0, index), update, ...steps.slice(index + 1)];
};

type StepsContextType = {
  workflowSchema: Schema;
  protocolSteps: ProtocolStep[];
  selectedStep: ProtocolStep;
  createStep: () => void;
  handleSelectStep: (step: ProtocolStep) => void;
  /** updates display names and descriptions based on the provided step */
  updateStepDisplay: (step: ProtocolStep) => void;
  toggleStepInput: (
    step: ProtocolStep,
    elementInstanceId: string,
  ) => (param: Parameter, checked: boolean) => void;
  toggleStepOutput: (
    step: ProtocolStep,
    elementInstanceId: string,
  ) => (param: Parameter, checked: boolean) => void;
  deleteStep: (stepId: string) => void;
  deleteStepInput: (step: ProtocolStep) => (index: number) => void;
  deleteStepOutput: (step: ProtocolStep) => (index: number) => void;
};

export const StepsContext = createContext<StepsContextType | undefined>(undefined);

export const useStepsContext = () => {
  const context = useContext(StepsContext);

  if (context === undefined) {
    throw new Error('useStepsContext must be used within a StepsProvider');
  }

  return context;
};

type StepsProviderProps = {
  schema: Schema;
  steps: ProtocolStep[];
};

export const StepsProvider: FC<StepsProviderProps> = ({ schema, steps, children }) => {
  // lazy initial state instead of a reducer. The state we manage is not too
  // complex and involves updating and splicing arrays, which isn't more
  // convenient using dispatch updates
  //
  // Workflow schema provides the functional aspect of a step, while protocol
  // (instance) steps provide the presentational aspect. Other components will
  // need to potentially update either entity without triggering re-renders or
  // race conditions. Hence these internal steps are the single source of truth.
  const [stepStates, setStepStates] = useState<StepState[]>(() =>
    newStepStates(schema, steps),
  );

  const workflowSchema: Schema = useMemo(() => {
    return {
      inputs: stepStates.flatMap<SchemaInput>(({ inputs }) =>
        inputs.map(i => {
          return {
            id: i.id,
            typeName: i.typeName,
            path: i.path,
            default: i.default,
            contextId: i.contextId,
          };
        }),
      ),
      outputs: stepStates.flatMap<SchemaOutput>(({ outputs }) =>
        outputs.map(o => {
          return { id: o.id, typeName: o.typeName, path: o.path };
        }),
      ),
    };
  }, [stepStates]);

  const protocolSteps: ProtocolStep[] = useMemo(() => {
    return stepStates.map(state => {
      return {
        id: state.id,
        displayName: state.displayName,
        // getElementId(i.path)! since path must be defined and we only support
        // element paths atm
        inputs: state.inputs.map<ProtocolStepInput>(i => {
          return {
            id: i.id,
            elementInstanceId: getElementId(i.path)!,
            displayName: i.displayName,
            displayDescription: i.displayDescription || ('' as Markdown),
            configuration: i.configuration,
          };
        }),
        outputs: state.outputs.map<ProtocolStepOutput>(o => {
          return {
            id: o.id,
            elementInstanceId: getElementId(o.path)!,
            displayName: o.displayName,
          };
        }),
      };
    });
  }, [stepStates]);

  const { selectedStep, handleSelectStep } = useProtocolsParamState(protocolSteps);

  const createStep = () =>
    setStepStates([
      ...stepStates,
      {
        id: uuid(),
        displayName: `New Step ${stepStates.length + 1}`,
        inputs: [],
        outputs: [],
      },
    ]);

  const updateStepDisplay = (step: ProtocolStep) => {
    const index = stepStates.findIndex(({ id }) => id === step.id);
    if (index !== undefined) {
      const state = stepStates[index];
      const inputsById = Object.fromEntries(step.inputs.map(input => [input.id, input]));
      const outputsById = Object.fromEntries(
        step.outputs.map(output => [output.id, output]),
      );

      const update: StepState = {
        ...state,
        displayName: step.displayName,
        inputs: state.inputs.map(stateInput => {
          const input = inputsById[stateInput.id];
          return input
            ? {
                ...stateInput,
                displayName: input.displayName,
                displayDescription: input.displayDescription,
              }
            : stateInput;
        }),
        outputs: state.outputs.map(stateInput => {
          const output = outputsById[stateInput.id];
          return output
            ? {
                ...stateInput,
                displayName: output.displayName,
              }
            : stateInput;
        }),
      };
      setStepStates([
        ...stepStates.slice(0, index),
        update,
        ...stepStates.slice(index + 1),
      ]);
    }
  };

  const toggleStepInput =
    (step: ProtocolStep, elementInstanceId: string) =>
    (param: Parameter, checked: boolean) => {
      const index = stepStates.findIndex(({ id }) => id === step.id);
      if (index !== undefined) {
        const result = updateStepStates(stepStates, index, {
          input: {
            add: checked
              ? {
                  id: uuid(),
                  path: newElementPath(elementInstanceId, param.name),
                  typeName: param.type,
                  displayName: param.configuration?.displayName || param.name,
                  displayDescription: param.configuration?.displayDescription as Markdown,
                  configuration: param.configuration?.editor,
                }
              : undefined,
            removeByPath: !checked
              ? newElementPath(elementInstanceId, param.name)
              : undefined,
          },
        });
        setStepStates(result);
      }
    };

  const toggleStepOutput =
    (step: ProtocolStep, elementInstanceId: string) =>
    (param: Parameter, checked: boolean) => {
      const index = stepStates.findIndex(({ id }) => id === step.id);
      if (index !== undefined) {
        const result = updateStepStates(stepStates, index, {
          output: {
            add: checked
              ? {
                  id: uuid(),
                  path: newElementPath(elementInstanceId, param.name),
                  typeName: param.type,
                  displayName: param.configuration?.displayName || param.name,
                }
              : undefined,
            removeByPath: !checked
              ? newElementPath(elementInstanceId, param.name)
              : undefined,
          },
        });
        setStepStates(result);
      }
    };

  const deleteStep = (stepId: string) => {
    const result = stepStates.filter(({ id }) => id !== stepId);
    setStepStates(result);
  };

  const deleteStepInput = (step: ProtocolStep) => (index: number) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex !== undefined) {
      const state = stepStates[stateIndex];
      const result = updateStepStates(stepStates, stateIndex, {
        input: { removeById: state.inputs[index]?.id },
      });
      setStepStates(result);
    }
  };

  const deleteStepOutput = (step: ProtocolStep) => (index: number) => {
    const stateIndex = stepStates.findIndex(({ id }) => id === step.id);
    if (stateIndex) {
      const state = stepStates[stateIndex];
      const result = updateStepStates(stepStates, stateIndex, {
        output: { removeById: state.outputs[index]?.id },
      });
      setStepStates(result);
    }
  };

  const state = {
    workflowSchema,
    protocolSteps,
    selectedStep,
    createStep,
    handleSelectStep,
    updateStepDisplay,
    toggleStepInput,
    toggleStepOutput,
    deleteStep,
    deleteStepInput,
    deleteStepOutput,
  };

  return <StepsContext.Provider value={state}>{children}</StepsContext.Provider>;
};
