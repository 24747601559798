import React from 'react';

import Button from 'common/ui/components/Button';

type DialogActionProps = {
  selectMessage: string;
  onSelectButtonClick: () => void;
};

export const ListViewActions = ({
  selectMessage,
  onSelectButtonClick,
}: DialogActionProps) => {
  const goToInventory = () => window.open('/#/liquid-policies', '_blank');
  return (
    <>
      <Button variant="tertiary" onClick={goToInventory}>
        Go to inventory
      </Button>
      <Button variant="tertiary" onClick={onSelectButtonClick} color="primary">
        {selectMessage}
      </Button>
    </>
  );
};

export const DetailsViewActions = ({
  selectMessage,
  onSelectButtonClick,
}: DialogActionProps) => (
  <Button
    variant="tertiary"
    onClick={onSelectButtonClick}
    color="primary"
    sx={{ marginLeft: 'auto' }}
  >
    {selectMessage}
  </Button>
);
