import {
  allConditions,
  allConsequences,
  RuleMetaKey,
} from 'client/app/apps/policy-library/RulesMeta';
import { EditorType } from 'common/elementConfiguration/EditorType';
import { capitalize } from 'common/lib/format';
import { Markdown } from 'common/lib/markdown';
import { ColumnState, SheetState } from 'common/rules/evaluateSpreadsheetRules';
import { Option } from 'common/types/Option';
import { ColumnConfiguration } from 'common/types/spreadsheet';
import { DataTable } from 'common/types/spreadsheetEditor';
import { getSensibleMeasurementUnits } from 'common/ui/components/ParameterEditors/unitRegistry';
import { TableConfiguration } from 'common/ui/components/Table';

export const conditionOptions: Option<RuleMetaKey>[] = Object.entries(allConditions)
  .map(([key, { label }]) => ({ label: capitalize(label), value: key as RuleMetaKey }))
  .filter(option => option.value !== 'liquid_type');

export const consequenceOptions: Option<RuleMetaKey>[] = Object.entries(
  allConsequences,
).map(([key, { label }]) => ({ label: capitalize(label), value: key as RuleMetaKey }));

export const initTableConfig: TableConfiguration = {
  canAddColumns: true,
  columns: [
    makeColumnConfig({
      name: 'rule_name',
      displayName: 'Rule Name',
      description: 'Name of the rule in a liquid policy',
      editor: {
        type: EditorType.STRING,
        additionalProps: null,
      },
    }),
  ],
};
export const initDataTable: DataTable = {
  schema: {
    fields: [{ name: 'rule_name', type: 'string' }],
  },
  data: [{ rule_name: 'Rule_1' }],
};
export const getTableStateFromConfig = (config: TableConfiguration): SheetState => ({
  columns: config.columns.reduce((result, nextColumn) => {
    result[nextColumn.name] = { isRequired: true };
    return result;
  }, {} as Record<string, ColumnState>),
});

export type RuleMetaDataType = keyof typeof ruleValueEditorConfigMap;

const ruleValueEditorConfigMap = {
  $string_array: {
    type: EditorType.STRING,
    additionalProps: null,
  },
  $string: {
    type: EditorType.STRING,
    additionalProps: null,
  },
  $bool: {
    type: EditorType.TOGGLE,
    additionalProps: null,
  },
  $float: {
    type: EditorType.FLOAT,
    additionalProps: null,
  },
  $unsigned_int: {
    type: EditorType.INT,
    additionalProps: null,
  },
  $duration: makeMeasurementConfig(getSensibleMeasurementUnits('Time'), 's'),
  $flow_rate: makeMeasurementConfig(
    getSensibleMeasurementUnits('Volume').flatMap(l =>
      getSensibleMeasurementUnits('Time').map(t => `${l}/${t}`),
    ),
    'ul/s',
  ),
  $length: makeMeasurementConfig(getSensibleMeasurementUnits('Length'), 'mm'),
  $speed: makeMeasurementConfig(
    getSensibleMeasurementUnits('Length').flatMap(l =>
      getSensibleMeasurementUnits('Time').map(t => `${l}/${t}`),
    ),
    'mm/s',
  ),
  $volume: makeMeasurementConfig(getSensibleMeasurementUnits('Volume'), 'ul'),
  $config: makeDropdownConfig(['auto', 'on', 'off']),
  $proxy_liquid: makeDropdownConfig([
    'FastAqueous',
    'Glycerol',
    'Ethanol',
    'System',
    'Robo',
    'Aqueous',
    'Surfactant',
    'Serum',
    'DMSO',
  ]),
  $well_reference: makeDropdownConfig(['well_bottom', 'well_top', 'liquid_level']),
  $well_zone: makeDropdownConfig(['bottom', 'liquid', 'top']),
  $device_manufacturer: makeDropdownConfig(['Gilson', 'Hamilton', 'Labcyte', 'Tecan']),
  $device_model: makeDropdownConfig([
    'Echo520',
    'Echo525',
    'Echo550',
    'Echo555',
    'Echo650',
    'Echo655',
    'Evo',
    'Fluent',
    'Pipetmax',
    'Star',
  ]),
};

function makeMeasurementConfig(
  units: string[],
  defaultUnit: string,
): ColumnConfiguration['editor'] {
  return {
    type: EditorType.MEASUREMENT,
    additionalProps: {
      editor: EditorType.MEASUREMENT,
      units,
      defaultUnit,
    },
  };
}

function makeDropdownConfig(options: string[]): ColumnConfiguration['editor'] {
  return {
    type: EditorType.DROPDOWN,
    additionalProps: {
      editor: EditorType.DROPDOWN,
      options: options.map(option => ({ label: option, value: option })),
      useDynamicOptions: false,
    },
  };
}

export const getRuleValueEditorConfig = (
  type: RuleMetaDataType,
): ColumnConfiguration['editor'] =>
  ruleValueEditorConfigMap[type] as ColumnConfiguration['editor'];

export function makeColumnConfig({
  name,
  displayName,
  description,
  editor,
  dataType = 'string',
}: {
  name: string;
  displayName: string;
  description: string;
  dataType?: ColumnConfiguration['dataType'];
  editor: ColumnConfiguration['editor'];
}): ColumnConfiguration {
  return {
    name,
    displayName,
    description: description as Markdown,
    dataType,
    editor,
    anthaType: 'string',
    dragToFillBehaviour: 'copy',
    hasTrailingGap: false,
  };
}
export function getColumnDataType(
  type: RuleMetaDataType,
): ColumnConfiguration['dataType'] {
  switch (type) {
    case '$bool':
      return 'boolean';
    case '$float':
    case '$unsigned_int':
      return 'number';
    default:
      return 'string';
  }
}
