import { sanitize } from 'dompurify';

import { State } from 'client/app/apps/policy-library/components/CreatePolicyContext';
import { allConditions, allConsequences } from 'client/app/apps/policy-library/RulesMeta';
import { CreateLiquidPolicyInput } from 'client/app/gql';
import { parseMeasurement } from 'common/lib/format';
import { Measurement, Rule } from 'common/types/mix';
import { Row } from 'common/types/spreadsheetEditor';

/**
 * Transforms dialog state for Liquid Policy into a graphql input where `rule`
 * are compatible with the `customPolicyRuleSet` object format
 */
export default function buildPolicyInput(
  policy: State['policy'],
): CreateLiquidPolicyInput {
  if (!policy.name.value) {
    throw new Error('Liquid policy name is not defined');
  }
  if (!policy.description.value) {
    throw new Error('Liquid policy description is not defined');
  }
  const policyName = sanitize(policy.name.value.trim());
  const policyDesc = sanitize(policy.description.value.trim());
  return {
    name: policyName,
    description: policyDesc,
    rules: buildRules(policy.rules.dataTable.data, policyName),
  };
}

/**
 * Transforms rules from DataTable into format compatible with `customPolicyRuleSet`
 */
function buildRules(rows: Row[], policyName: string): Rule[] {
  return rows.map(row => {
    const name = row['rule_name'] as string;
    const conditions: Rule['conditions'] = [
      {
        variable: 'liquid_type',
        values: [policyName],
      },
    ];
    const consequences: Rule['consequences'] = {};

    for (const key in row) {
      const cellValue = row[key];

      if (cellValue == null) continue;

      // Process conditions
      if (key in allConditions) {
        const value = String(cellValue);
        const measurement = parseMeasurement(value);
        const condition = measurement
          ? {
              variable: key,
              value: measurement,
              operator: measurement ? 'gte' : undefined,
            }
          : {
              variable: key,
              // TODO: handle cases with tip types: "Fluent Tips, 100 ul", "Fluent Tips, 20 ul"
              values: value.split(','),
            };
        conditions.push(condition);

        // Process consequences
      } else if (key in allConsequences) {
        let measurement: Measurement | undefined;
        if (typeof cellValue === 'string') {
          measurement = parseMeasurement(cellValue);
        }
        // TODO: handle complex volume types VolumeConsequence
        consequences[key] = measurement ?? cellValue;
      }
    }
    return {
      name,
      source: 'user',
      conditions,
      consequences,
    };
  });
}
