import React, { useCallback, useState } from 'react';

import { ApolloError, useMutation } from '@apollo/client';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Share from '@mui/icons-material/Share';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import { css, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import DOMPurify from 'dompurify';

import { MUTATION_GENERATE_SHARED_LINK_ID } from 'client/app/api/gql/mutations';
import { useCurrentEntity } from 'client/app/components/nav/breadcrumbs/BreadcrumbsEntityContext';
import BreadcrumbsMenu from 'client/app/components/nav/breadcrumbs/components/BreadcrumbsMenu';
import NameEditor from 'client/app/components/nav/breadcrumbs/components/NameEditor';
import { type NavNode } from 'client/app/components/nav/breadcrumbs/types';
import useBreadcrumbsEntity from 'client/app/components/nav/breadcrumbs/useBreadcrumbsEntity';
import ShareLinkDialog from 'client/app/components/ShareLink/ShareLinkDialog';
import { BreadcrumbsEntityEnum } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';
import stopPropagation from 'common/lib/stopPropagation';
import { SharedEntityType } from 'common/types/sharedLinks';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import { useDialogManager } from 'common/ui/components/DialogManager';
import IconButton from 'common/ui/components/IconButton';
import { useNavigation } from 'common/ui/components/navigation/useNavigation';
import { useSnackbarManager } from 'common/ui/components/SnackbarManager';
import Tooltip from 'common/ui/components/Tooltip';
import { usePopover } from 'common/ui/hooks/usePopover';
import { route } from 'common/ui/navigation';
import { trackHeapEvent } from 'common/ui/useHeapTracking';

type NavNodeVariant = 'header' | 'menu';

type Props = {
  node: NavNode;
  expanded?: boolean;
  variant?: NavNodeVariant;
};

export default function NavNode({ node, expanded, variant = 'header' }: Props) {
  const isSharingEnabled = useFeatureToggle('USER_LINK_SHARING');
  const entity = useBreadcrumbsEntity(node);
  const {
    currentSubItem,
    title,
    color,
    url,

    openInNewTab,
    navigateToEntity,
    renameEntity,

    EntityIcon,
    Dropdown: ItemsDropdown,
  } = entity;

  const [isEditing, setEditing] = useState(false);
  const snackbarManager = useSnackbarManager();
  const [generateShareLinkIdMutation] = useMutation(MUTATION_GENERATE_SHARED_LINK_ID, {
    onError: (error: ApolloError) => {
      reportError(new Error(error.message));
      snackbarManager.showError(error.message);
    },
  });

  const currentEntity = useCurrentEntity();

  const handleSave = useCallback(
    async (newNameValue: string) => {
      if (!currentSubItem) return;

      const newName = DOMPurify.sanitize(newNameValue.trim());
      const oldName = currentSubItem.name;

      if (newName && newName !== oldName) {
        await renameEntity?.(newName);
        trackHeapEvent('rename-entity', {
          source: 'breadcrumbs',
          previousName: oldName,
          newName: newNameValue,
          entityType: node.entity,
        });
      }
      setEditing(false);
    },
    [currentSubItem, node.entity, renameEntity],
  );

  const isDefinedCurrentItem = !!currentSubItem;
  const isPlaceholder =
    node.items.length === 0 && node.entity !== BreadcrumbsEntityEnum.WORK_TREE;

  const showItemInfo = isDefinedCurrentItem && expanded;
  const showDropdown = !isEditing && !!ItemsDropdown;
  const canShare =
    isSharingEnabled && isDefinedCurrentItem && url && expanded && variant === 'header';

  const canNavigate = isDefinedCurrentItem && (!expanded || variant === 'menu');
  const canEditName = currentSubItem?.isEditable && expanded && variant === 'header';

  const Container = variant === 'header' ? HeaderContainer : MenuContainer;

  const dialogManager = useDialogManager();

  const handleShareLinkClick = async () => {
    if (currentSubItem && url) {
      const { data } = await generateShareLinkIdMutation({
        variables: {
          entityId: currentSubItem?.id,
          entityType: convertToSharedEntityType(node.entity),
        },
      });
      const shareLinkId = data?.generateSharedLinkId.sharedLinkId;
      if (shareLinkId) {
        openShareLinkDialog(shareLinkId);
      } else {
        const error = `No sharedLinkId value was provided for entity: ${url} `;
        reportError(new Error(error));
      }
    }
  };

  const openShareLinkDialog = useCallback(
    (shareLinkId: string) => {
      if (currentSubItem && url) {
        const appendedURL = appendShareLinkId(url, 'sharelinkid', shareLinkId);
        dialogManager.openDialog('SHARE_LINK', ShareLinkDialog, {
          entityLink: appendedURL,
          entityName: currentSubItem?.name,
          entityType: node.entity,
        });
      }
    },
    [currentSubItem, dialogManager, node.entity, url],
  );

  const iconColor = isPlaceholder
    ? Colors.TEXT_DISABLED
    : expanded && variant !== 'menu'
    ? color
    : 'inherit';

  return (
    <Container
      onClick={() => canNavigate && navigateToEntity(currentSubItem)}
      onAuxClick={() => canNavigate && openInNewTab(currentSubItem)}
      onDoubleClick={() => canEditName && setEditing(true)}
      data-heap-tracking={`breadcrumbs-nav-node-${node.entity}`}
      expanded={expanded}
      isPlaceholder={isPlaceholder}
    >
      <Tooltip
        title={
          isDefinedCurrentItem &&
          !expanded && (
            <Stack direction="row">
              {title && (
                <TooltipTitle>
                  {title}
                  {currentSubItem.name && ':'}
                </TooltipTitle>
              )}
              <TooltipName>{currentSubItem.name}</TooltipName>
            </Stack>
          )
        }
        PopperProps={{ sx: { '& .MuiTooltip-tooltip': { paddingX: 4, paddingY: 3 } } }}
      >
        <Stack>
          <EntityIcon
            sx={{
              color: iconColor,
              marginRight: expanded ? 3 : 0,
            }}
          />
        </Stack>
      </Tooltip>
      {showItemInfo &&
        (isEditing ? (
          <NameEditor name={currentSubItem.name} onSave={handleSave} />
        ) : (
          <>
            {title && (
              <Title>
                {title}
                {currentSubItem.name && ':'}
              </Title>
            )}
            <Name
              data-heap-tracking="breadcrumbs-name-readonly-text"
              title={currentSubItem.name}
              sx={styleName(currentSubItem.isEditable, variant)}
            >
              {currentSubItem.name}
            </Name>
            {expanded && currentEntity.hasExtendedInfo && (
              <InfoButton
                size="xsmall"
                icon={<InfoIcon />}
                onClick={() => currentEntity.toggleShowInfo()}
              />
            )}
          </>
        ))}
      {showDropdown && (
        <ItemsDropdown node={node} entity={entity} expanded={!!expanded} />
      )}
      {canShare && (
        <ShareButton
          variant="secondary"
          color="primary"
          endIcon={<Share />}
          size="small"
          onClick={handleShareLinkClick}
          data-heap-tracking="share-link"
        >
          Share
        </ShareButton>
      )}
    </Container>
  );
}

export function NodeSkeleton({ expanded }: { expanded: boolean }) {
  return (
    <HeaderContainer expanded={expanded}>
      <Skeleton
        variant="text"
        height="100%"
        width={expanded ? MIN_NAME_WIDTH : MIN_WIDTH / 2}
      />
    </HeaderContainer>
  );
}

export function RootNode({ children }: React.PropsWithChildren<{}>) {
  const { navigate, getAbsoluteURL } = useNavigation();
  const navigateHome = () => {
    navigate(route('/'), undefined);
  };
  const openInNewTab = () => {
    window.open(getAbsoluteURL(route('/'), undefined), '_blank');
  };

  return (
    <HeaderContainer
      data-heap-tracking="breadcrumbs-root-node-container"
      className="root"
      onClick={navigateHome}
      onAuxClick={openInNewTab}
    >
      {children}
    </HeaderContainer>
  );
}

export function CollapsedNode({ nodes }: { nodes: NavNode[] }) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    stopPropagation(event);
    onShowPopover(event);
  };
  const handleAnyMenuClick = (event: React.MouseEvent) => {
    stopPropagation(event);
    onHidePopover();
  };

  return (
    <HeaderContainer onClick={handleClick}>
      <MoreHorizOutlinedIcon />
      <BreadcrumbsMenu
        anchorEl={popoverAnchorElement}
        open={isPopoverOpen}
        onClose={onHidePopover}
        onClick={handleAnyMenuClick}
        onDoubleClick={stopPropagation}
        disableAutoFocusItem
      >
        <Stack gap={1}>
          {nodes.map((node, index) => (
            <CollapsedItemContainer
              key={index}
              useChildButtonForClickEvent={node.items.length > 1}
              disableRipple={node.items.length > 1}
            >
              <NavNode node={node} expanded variant="menu" />
            </CollapsedItemContainer>
          ))}
        </Stack>
      </BreadcrumbsMenu>
    </HeaderContainer>
  );
}

function styleName(isEditable: boolean, variant: NavNodeVariant) {
  const menuStyle = {
    color: Colors.TEXT_PRIMARY,
    maxWidth: '400px',
  };
  const enabledStyle = {
    '&:hover': {
      cursor: 'pointer',
      color: Colors.PRIMARY_MAIN,
    },
  };
  const disabledStyle = { color: Colors.TEXT_PRIMARY };

  switch (variant) {
    case 'menu':
      return menuStyle;
    case 'header':
    default:
      return isEditable ? enabledStyle : disabledStyle;
  }
}

function convertToSharedEntityType(entityType: BreadcrumbsEntityEnum): SharedEntityType {
  return SharedEntityType[entityType];
}

function appendShareLinkId(url: string, paramName: string, paramValue: string) {
  const encodedParamValue = encodeURIComponent(paramValue);
  const separator = url.includes('?') ? '&' : '?';
  return `${url}${separator}${paramName}=${encodedParamValue}`;
}

// #region Styles

const MIN_WIDTH = 62;
const MIN_NAME_WIDTH = 250;

const MenuContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled('div')<{ expanded?: boolean; isPlaceholder?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: ${MIN_WIDTH}px;
  width: fit-content;
  position: relative;
  z-index: 1;
  clip-path: var(--mid-path);
  background: ${Colors.GREY_30};
  padding: ${({ theme }) => theme.spacing(0, 6, 0, 6)};

  &:first-of-type {
    clip-path: var(--left-path);
    padding-left: ${({ theme }) => theme.spacing(5)};

    &:after {
      left: 0;
    }
  }

  &:not(:first-of-type) {
    margin-left: calc(calc(var(--sharpness) * -1) - 1px);
  }

  &:only-of-type {
    clip-path: none;
    &:after {
      right: 0;
    }
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0 1px 0 1px;
    background: ${({ isPlaceholder }) => (isPlaceholder ? Colors.GREY_10 : '#fff')};
    z-index: -1;
    clip-path: inherit;
  }

  ${({ expanded, isPlaceholder }) =>
    !(expanded || isPlaceholder)
      ? css`
          &:not(:only-of-type):hover {
            cursor: pointer;
            background: ${Colors.BLUE_20};
            z-index: 2;

            &:after {
              background-color: ${Colors.BLUE_5};
            }
          }
        `
      : ''}
`;

const CollapsedItemContainer = styled(MenuItem, {
  shouldForwardProp: propName => propName !== 'useChildButtonForClickEvent',
})<{ useChildButtonForClickEvent: boolean }>(
  ({ theme, useChildButtonForClickEvent }) => ({
    minWidth: 286,
    padding: theme.spacing(3, 5),
    '&:first-of-type': {
      paddingTop: theme.spacing(4),
    },
    '&:last-child': {
      paddingBottom: theme.spacing(4),
    },
    ...(useChildButtonForClickEvent
      ? {
          pointerEvents: 'none',
          '&:hover': {
            backgroundColor: theme.palette.background.paper,
          },
          '& button': {
            pointerEvents: 'auto',
          },
        }
      : {
          cursor: 'pointer',
        }),
  }),
);

const Title = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.secondary,
  userSelect: 'none',
  marginRight: theme.spacing(1),
}));

const Name = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: theme.palette.text.primary,
  overflowX: 'hidden',
  userSelect: 'none',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
}));

const TooltipTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: Colors.DARK_TEXT_SECONDARY,
  marginRight: theme.spacing(2),
}));

const TooltipName = styled(Typography)(({ theme }) => ({
  ...theme.typography.subtitle2,
  color: Colors.DARK_TEXT_PRIMARY,
}));

const ShareButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(5),
  flexShrink: 0,
}));

const InfoButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.text.primary,
  marginLeft: theme.spacing(3),
}));

// #endregion
