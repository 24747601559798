import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { InstanceParameter } from 'client/app/components/Parameters/ElementParameterGroupList';
import { useFilterPlateContentParameters } from 'client/app/components/Parameters/PlateContents/lib/plateContentsEditorUtils';
import { ParameterStateContext } from 'client/app/lib/rules/elementConfiguration/ParameterStateContext';
import { ParameterValue } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';

type Props = {
  updateParams: (schemaInputId: string, value: any) => void;
};

export const InputStep = ({ updateParams }: Props) => {
  const { selectedStep } = useStepsContext();
  const {
    config: workflowConfig,
    getSchemaParameter,
    updateOutput,
    parameters,
  } = useWorkflowContext();

  const selectedInputParameters = useMemo(() => {
    return selectedStep.inputs.flatMap(input => {
      const { element, parameter } = getSchemaParameter(input.id);
      if (element && parameter) {
        const configuration = input.configuration && {
          editor: input.configuration,
          isVisible: true,
          displayDescription: input.displayDescription, // TODO - Is this the right display description?
          displayName: input.displayName,
        };
        const selectedInput = {
          id: input.id,
          element,
          parameter: {
            ...parameter,
            description: input.displayDescription,
            configuration,
          },
        };
        return [selectedInput];
      }
      return [];
    });
  }, [getSchemaParameter, selectedStep.inputs]);

  // We filter out some parameters that are managed by the PlateContentsEditor component.
  const { plateContentParams, plateParameterFilter } = useFilterPlateContentParameters([
    ...selectedInputParameters.map(({ parameter }) => parameter),
  ]);

  const { getStateForParameter } = useContext(ParameterStateContext);

  const handleParameterChange = useCallback(
    (schemaInputId: string) => {
      return (_paramName: string, value: ParameterValue, _instanceName?: string) => {
        updateParams(schemaInputId, value);
      };
    },
    [updateParams],
  );

  useEffect(() => {
    selectedStep.outputs.length && updateOutput(selectedStep.outputs[0].id);
  }, [selectedStep.outputs, updateOutput]);

  return (
    <Wrapper>
      <InputStepName variant="h4">{selectedStep.displayName}</InputStepName>
      <ContainerWithIntersectionBar
        dense
        noHeader
        content={
          <InputContentWrapper>
            {selectedInputParameters
              .filter(({ parameter }) => plateParameterFilter(parameter))
              .map(({ id: inputId, parameter, element }) => {
                return (
                  <InstanceParameter
                    key={inputId}
                    instanceName={element.name}
                    elementId={element.Id}
                    paramState={getStateForParameter(element.name, parameter.name)}
                    showValidation
                    onChange={handleParameterChange(inputId)}
                    parameter={parameter}
                    workflowConfig={workflowConfig}
                    defaultParameters={{}}
                    parameterValueDict={parameters[element.name]}
                    paramValue={parameters[element.name][parameter.name]}
                    plateContentParams={plateContentParams}
                  />
                );
              })}
          </InputContentWrapper>
        }
      />
    </Wrapper>
  );
};

export function InputStepSkeleton() {
  const { selectedStep } = useStepsContext();
  return (
    <Wrapper>
      <InputStepName variant="h4">{selectedStep.displayName}</InputStepName>
      <InputContentWrapper>
        <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
        <Skeleton sx={{ mb: 5 }} variant="rounded" width="100%" height={50} />
        <Skeleton sx={{ mb: 3 }} variant="rounded" width="100%" height={20} />
        <Skeleton variant="rounded" width="100%" height={50} />
      </InputContentWrapper>
    </Wrapper>
  );
}

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  minWidth: '376px',
  maxWidth: '376px',
  padding: theme.spacing(5),
  gap: theme.spacing(5),
  borderRadius: theme.spacing(3, 0, 0, 3),
  border: `1px solid ${Colors.GREY_30}`,
  backgroundColor: 'white',
}));

const InputStepName = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const InputContentWrapper = styled('div')({
  // InstanceParameter component renders some map buttons in an absolute position
  // with a negative left value, which we need to account for here to ensure they
  // are displayed correctly.
  marginLeft: '8px',
});
