// TODO - Remove this once we implement query for all protocols on ProtocolScreen.
export const MockProtocols: {
  name: string;
  workflowId: string;
  protocolId: string;
  protocolInstanceId: string;
}[] = [
  {
    name: 'DC Protein Quantification Assay',
    workflowId: 'bf70acb8-11e5-4049-9547-fef8cdb8ecee',
    protocolId: '03c31d42-f634-42d2-aa90-564112b1ba31',
    protocolInstanceId: '8e706ccc-fa5e-46b2-87c5-5946d543c5cc',
  },
  {
    name: 'Mycoplasma Luminescence Assay',
    workflowId: '945816a5-8284-48c2-8a99-b7f1a46dff45',
    protocolId: '8d7a919d-5814-4d72-b45a-14f7d3b1af82',
    protocolInstanceId: 'c43f6f46-7804-4df6-970e-60340c5208b4',
  },
];
