import { useMutation } from '@apollo/client';
import { GraphQLError } from 'graphql';

import { ClickedExecution } from 'client/app/apps/experiments/ExecutionsList';
import {
  MUTATION_CREATE_METHOD,
  MUTATION_EXCHANGE__TO_RESULTS,
} from 'client/app/apps/work-tree/mutations';
import { WorkTreeApplicationName, WorkTreeResultType } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';

/**
 * Uses the given executions create a prepare_data method from these executions
 * and then navigate to the newly created prepare_data page.
 */
export default function useLaunchPrepareData() {
  const [createMethodMutation, { loading: createMethodLoading }] =
    useMutation(MUTATION_CREATE_METHOD);

  const [exchangeToResultsMutation, { loading: exchangeToResultsLoading }] = useMutation(
    MUTATION_EXCHANGE__TO_RESULTS,
  );

  const isDOEPrepareDataImprovementsEnabled = useFeatureToggle(
    'DOE_PREPARE_DATA_IMPROVEMENTS',
  );

  const launchPrepareData = async (
    executions: ClickedExecution[],
    logCategory: string,
  ): Promise<{ error: GraphQLError | undefined }> => {
    // We require the exections to exist as results in the db.
    // This will create entries if they do not exist, and return those
    // (or return the existing result entries).
    const { data: exhangeToResultsData, errors: exchangeToResultsErrors } =
      await exchangeToResultsMutation({
        variables: {
          inputs: executions.map(execution => {
            return {
              id: execution.executionId,
              type: WorkTreeResultType.EXECUTION,
            };
          }),
        },
      });
    if (!exhangeToResultsData?.exchangeToResults || exchangeToResultsErrors?.[0]) {
      return {
        error:
          exchangeToResultsErrors?.[0] ??
          new GraphQLError('Could not convert executions to results'),
      };
    }
    if (exhangeToResultsData?.exchangeToResults) {
      const { data: method, errors: methodErrors } = await createMethodMutation({
        variables: {
          applicationName: WorkTreeApplicationName.doe_prepare_data,
          applicationVersion: isDOEPrepareDataImprovementsEnabled
            ? '1.0.0-dev'
            : 'latest',
          inputs: exhangeToResultsData.exchangeToResults.map(result => {
            return {
              id: result.id,
              type: result.resultType,
            };
          }),
          issuedFrom: logCategory,
        },
      });
      if (method?.worktreeCreateMethod) {
        window.location.href = method.worktreeCreateMethod.href;
      } else {
        return {
          error:
            methodErrors?.[0] ?? new GraphQLError('Could not create prepare data method'),
        };
      }
    }
    return { error: undefined };
  };

  return {
    launchPrepareData,
    loading: createMethodLoading || exchangeToResultsLoading,
  };
}
