import { useCallback, useState } from 'react';

import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import { LiquidPolicy } from 'client/app/gql';
import { DialogProps } from 'common/ui/hooks/useDialog';

export enum Views {
  LIST,
  DETAILS,
}

type Props = {
  value: string;
} & DialogProps<string | undefined | typeof CANCEL_CHOICE>;

export default function usePolicyHandlers({
  value,
  onClose,
}: Pick<Props, 'value' | 'onClose'>) {
  const [policyParameterValue, setPolicyParameterValue] = useState<string | undefined>(
    value,
  );

  // Policy handlers
  const [selectedPolicy, setSelectedPolicy] = useState<LiquidPolicy | undefined>();
  const togglePolicy = useCallback(
    (policy: LiquidPolicy) => {
      if (policy.name === selectedPolicy?.name) {
        setSelectedPolicy(undefined);
        setPolicyParameterValue(undefined);
      } else {
        setSelectedPolicy(policy);
        setPolicyParameterValue(policy.name);
      }
    },
    [selectedPolicy?.name],
  );
  const selectPolicyAndCloseDialog = useCallback(
    (policy: LiquidPolicy) => {
      setSelectedPolicy(policy);
      setPolicyParameterValue(policy.name);
      onClose(policy.name);
    },
    [onClose],
  );

  // View handlers
  const [activeView, setActiveView] = useState(Views.LIST);
  const showList = useCallback(() => {
    setActiveView(Views.LIST);
  }, []);
  const showDetails = useCallback((policy: LiquidPolicy) => {
    setSelectedPolicy(policy);
    setPolicyParameterValue(policy.name);
    setActiveView(Views.DETAILS);
  }, []);

  // Dialog handlers
  const submitDialog = useCallback(() => {
    onClose(policyParameterValue);
  }, [onClose, policyParameterValue]);

  const goBack = activeView === Views.DETAILS ? showList : undefined;

  const cancelDialog = useCallback(() => {
    setSelectedPolicy(undefined);
    setPolicyParameterValue(value);
    onClose(CANCEL_CHOICE);
  }, [onClose, value]);

  return {
    policyParameterValue,
    policy: {
      ...selectedPolicy,
      toggle: togglePolicy,
      selectAndClose: selectPolicyAndCloseDialog,
    },
    view: {
      active: activeView,
      showList,
      showDetails,
    },
    dialog: {
      submit: submitDialog,
      cancel: cancelDialog,
      goBack,
    },
  };
}
