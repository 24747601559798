import React, { useState } from 'react';

import CreatePolicy from 'client/app/apps/policy-library/components/CreatePolicy';
import PreviewPolicyDialog from 'client/app/apps/policy-library/components/PreviewDialog';
import PolicyLibraryAppNew from 'client/app/apps/policy-library/PolicyLibraryApp.new';
import PolicyLibraryAppOld from 'client/app/apps/policy-library/PolicyLibraryApp.old';
import DetailsView from 'client/app/components/Parameters/Policy/components/DetailsView';
import { LiquidPolicy } from 'client/app/gql';
import { useFeatureToggle } from 'common/features/useFeatureToggle';

export default function PolicyLibraryScreen() {
  const isEnabledLiquidPolicyLibrary = useFeatureToggle('LIQUID_POLICY_LIBRARY');

  const [policy, setPolicy] = useState<LiquidPolicy | undefined>();

  return isEnabledLiquidPolicyLibrary ? (
    <>
      <PolicyLibraryAppNew onViewDetails={setPolicy} />
      {policy && (
        <PreviewPolicyDialog policy={policy} onClose={() => setPolicy(undefined)}>
          <DetailsView policy={policy} />
        </PreviewPolicyDialog>
      )}
      <CreatePolicy />
    </>
  ) : (
    <PolicyLibraryAppOld />
  );
}
