import React, { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import IconButton from 'common/ui/components/IconButton';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = DialogProps<void> & {};

export const PublishProtocolDialog = ({ isOpen, onClose }: Props) => {
  const handleOnClose = () => {
    onClose();
  };

  const [tags, setTags] = useState<string[]>([]);

  const handleOnPublish = () => {
    // TODO: Implement me!
  };

  return (
    <Dialog open={isOpen} onClose={handleOnClose}>
      <StyledDialogHeader>
        <Typography variant="h3">Publish protocol</Typography>
        <StyledDialogHeaderRightContentContainer>
          <IconButton
            icon={<CloseIcon />}
            onClick={handleOnClose}
            color="inherit"
            size="small"
          />
        </StyledDialogHeaderRightContentContainer>
      </StyledDialogHeader>
      <Main>
        <Stack gap={3}>
          <Typography variant="body1" fontWeight={500}>
            Tags
          </Typography>
          <Autocomplete
            multiple
            options={[]}
            value={tags}
            onChange={(_, newTags) => setTags(newTags)}
            renderInput={params => (
              <TextField {...params} variant="outlined" placeholder="Select tag(s)..." />
            )}
          />
        </Stack>
      </Main>
      <Actions>
        <Button color="primary" onClick={handleOnPublish} sx={{ padding: 0 }}>
          Publish
        </Button>
      </Actions>
    </Dialog>
  );
};

const StyledDialogHeader = styled('div')(({ theme: { spacing } }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  minHeight: '38px',
  padding: spacing(6),
  minWidth: '400px',
}));

const Main = styled('main')(({ theme: { spacing } }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing(5),
  padding: spacing(0, 6),

  overflow: 'auto',
}));

const Actions = styled(DialogActions)(({ theme: { spacing } }) => ({
  padding: spacing(6),
}));

const StyledDialogHeaderRightContentContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});
