import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function CustomPolicyIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7.167V9.75H8.75v15.5h20.667V15H32v10.25a2.583 2.583 0 0 1-2.583 2.583H8.75a2.583 2.583 0 0 1-2.583-2.583V9.75A2.583 2.583 0 0 1 8.75 7.167H21Z"
        fill="#333"
      />
      <path
        d="M24.158 4.973v.002h.004v6.956l2.746 1.45 2.746-1.45V4.975h.003v-.001h1.294v-.637c.014.076.021.154.021.233v.404h.955v1.134h-.955v5.823c0 .49-.27.938-.703 1.166l-2.745 1.45a1.318 1.318 0 0 1-1.231 0l-2.746-1.45a1.318 1.318 0 0 1-.703-1.166V6.108h-.956V4.973h2.27Z"
        fill="#333"
      />
      <path
        d="M25.096 7.743h3.624v3.647l-1.812.966-1.812-.966V7.743ZM28.39 4.105a1.49 1.49 0 0 1-1.482 1.497 1.49 1.49 0 0 1-1.483-1.497c0-.826 1.483-3.116 1.483-3.116s1.483 2.29 1.483 3.116ZM13.647 14.584c-.499.499-.585.705-.585 1.358 0 1.805 2.08 2.647 3.335 1.34C17.652 15.96 16.74 14 14.85 14c-.481 0-.756.137-1.203.584ZM17.6 14.378c.43.653.498 1.684.189 2.595-.155.465-.24.877-.189.928.206.224 1.41-.189 1.856-.635.344-.361.482-.653.482-1.118 0-1.254-.808-2.148-1.925-2.148-.654 0-.654.017-.413.378ZM13.492 18.692c-.997.258-1.564.533-2.097 1.031-.31.275-.395.533-.395 1.272v.911h7.906v-.945c0-.74-.086-1.031-.36-1.306-.946-.946-3.352-1.393-5.054-.963ZM19.302 18.898c.48.465.636.877.756 1.977l.103 1.031H22v-.91c0-.74-.086-.998-.378-1.273-.499-.464-1.272-.842-2.011-.996-.567-.12-.585-.104-.31.171Z"
        fill="#333"
      />
    </SvgIcon>
  );
}

export default React.memo(CustomPolicyIcon);
