import React, { useEffect, useRef, useState } from 'react';

import ClickAwayListener from '@mui/material/ClickAwayListener';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';

import { useEnterKeyPress } from 'common/ui/hooks/useEnterKeyPress';
import useTextFieldChange from 'common/ui/hooks/useTextFieldChange';

type Props = {
  name: string;
  onSave: (newName: string) => void;
};

/**
 * Editor which appears when an EditableName is being modified
 */
export default function NameEditor({ name, onSave }: Props) {
  const [value, setValue] = useState(name);

  const handleChange = useTextFieldChange(newValue => setValue(newValue));
  const saveNewName = () => {
    onSave(value);
  };
  const handleKeyDown = useEnterKeyPress(saveNewName);

  // ensure the input is focussed as soon as it is mounted
  const inputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    inputRef.current?.focus();
    inputRef.current?.select();
  }, []);

  return (
    <ClickAwayListener onClickAway={saveNewName}>
      <StyledTextField
        variant="outlined"
        fullWidth
        inputRef={inputRef}
        value={value}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={saveNewName}
      />
    </ClickAwayListener>
  );
}

const StyledTextField = styled(TextField)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '& input': {
    ...theme.typography.h5,
    fontWeight: 700,
    padding: `${theme.spacing(2)} ${theme.spacing(4)}`,
  },
}));
