import keys from 'lodash/keys';
import reduce from 'lodash/reduce';
import sortBy from 'lodash/sortBy';

import RulesMeta from 'client/app/components/Parameters/Policy/assets/rulesMeta.json';

const mergedConsequences = {
  ...RulesMeta.consequences['General Properties and Tip Behaviour'],
  ...RulesMeta.consequences.Aspirate,
  ...RulesMeta.consequences.Dispense,
  ...RulesMeta.consequences['Post-Dispense Behaviour'],
  ...RulesMeta.consequences['Pre-Mix'],
  ...RulesMeta.consequences['Post-Mix'],
  ...RulesMeta.consequences.Other,
};
export const allConditions = reduce(
  // Sort alphabetically
  sortBy(keys(RulesMeta.conditions)),
  (result, key) => {
    const metaKey = key as RuleConditionKey;
    result[metaKey] = RulesMeta.conditions[metaKey];
    return result;
  },
  {} as RuleConditions,
);

export const allConsequences = reduce(
  // Do not sort, render them as they are: grouped by sections
  keys(mergedConsequences),
  (result, key) => {
    const metaKey = key as RuleConsequenceKey;
    result[metaKey] = mergedConsequences[metaKey];
    return result;
  },
  {} as RuleConsequences,
);

type RuleConditions = typeof RulesMeta.conditions;
type RuleConsequences = typeof mergedConsequences;
type RuleConditionKey = keyof RuleConditions;
type RuleConsequenceKey = keyof RuleConsequences;

export type RuleMetaType = 'condition' | 'consequence';
export type RuleMetaKey = RuleConditionKey | RuleConsequenceKey;

export function getRuleMeta(type: RuleMetaType, key: RuleMetaKey) {
  switch (type) {
    case 'condition':
      return allConditions[key as RuleConditionKey];
    case 'consequence':
      return allConsequences[key as RuleConsequenceKey];
    default:
      throw new Error(`No type: ${type}`);
  }
}
