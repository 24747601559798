import { State } from 'client/app/apps/policy-library/components/CreatePolicyContext';
import { LiquidPolicy } from 'client/app/gql';

const NAME_REGEX = /^[A-Za-z0-9,_,-]+$/;

export function makeValidateName({
  liquidPolicies,
}: {
  liquidPolicies: LiquidPolicy[] | undefined;
}) {
  return (newName: string) => {
    const newNameProcessed = newName.trim().toLowerCase();

    const isEmpty = newNameProcessed === '';
    const hasInvalidCharacters = !NAME_REGEX.test(newName);
    const existPolicyName = liquidPolicies?.some(
      policy => policy.name.toLowerCase() === newNameProcessed,
    );

    let error: string | undefined;
    if (isEmpty) {
      error =
        'Policy name cannot be empty. Please, provide a name for your liquid policy.';
    } else if (hasInvalidCharacters) {
      error =
        'Policy name must only contain letters and numbers, with no spaces or special characters.';
    } else if (existPolicyName) {
      error = `Policy with name "${newName}" already exists. Please, provide a unique name for your liquid policy.`;
    }

    return error;
  };
}

export function validateDialogState(stateDraft: State): State['dialog'] {
  const invalidName = Boolean(
    stateDraft.policy.name.error ?? !stateDraft.policy.name.value,
  );
  const invalidDescription = Boolean(
    stateDraft.policy.description.error ?? !stateDraft.policy.description.value,
  );
  const noRules =
    stateDraft.policy.rules.dataTable.data.length === 0 ||
    // Each rule must have a name so there is always 1 column for rule name
    stateDraft.policy.rules.dataTable.schema.fields.length === 1;

  return {
    ...stateDraft.dialog,
    rulesTab: { disabled: invalidName || invalidDescription },
    addPolicyButton: { disabled: invalidName || invalidDescription || noRules },
  };
}
