import React from 'react';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { SimulationErrors } from 'client/app/apps/simulation-details/overview/SimulationErrorScreen';
import useElementErrorParser from 'client/app/components/ElementPlumber/useElementErrorParser';
import { ElementErrorData } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import { SimulationQuery } from 'client/app/gql';
import { stringToMarkdown } from 'common/lib/markdown';
import { ElementErorrSeverity } from 'common/types/bundle';
import Colors from 'common/ui/Colors';
import { MarkdownPreview } from 'common/ui/components/MarkdownPreview';

type Props = {
  simulation?: SimulationQuery['simulation'];
  elementErrors?: ElementErrorData[];
};

export default function OutputStepErrors({ simulation, elementErrors }: Props) {
  return (
    <Wrapper>
      <OutputErrorsTitle variant="h4">Preview Output</OutputErrorsTitle>
      {simulation?.status === 'FAILED' ? (
        <SimulationErrors simulation={simulation} />
      ) : (
        <ElementErrors elementErrors={elementErrors || []} />
      )}
    </Wrapper>
  );
}

function ElementErrors({ elementErrors }: { elementErrors: ElementErrorData[] }) {
  const parseError = useElementErrorParser();
  return (
    <ErrorListContainer>
      {elementErrors.map((error, index) => (
        <ErrorListItem key={index} severity={error.severity}>
          <Stack direction="row" alignItems="center" gap={3}>
            <StyledInfoOutlinedIcon severity={error.severity} />
            <ErrorHeader variant="subtitle2" severity={error.severity}>
              Protocol {error.severity === 'error' ? 'Error' : 'Warning'}
            </ErrorHeader>
          </Stack>
          <Stack gap={1}>
            <Typography variant="subtitle2">Summary</Typography>
            <MarkdownPreview
              key={index}
              markdown={stringToMarkdown(parseError(error.message))}
            />
          </Stack>
          <Stack gap={1}>
            <Typography variant="subtitle2">Details</Typography>
            <MarkdownPreview
              key={index}
              markdown={stringToMarkdown(parseError(error.details))}
            />
          </Stack>
        </ErrorListItem>
      ))}
    </ErrorListContainer>
  );
}

const OutputErrorsTitle = styled(Typography)(() => ({
  fontWeight: 600,
}));

const Wrapper = styled('div')<{}>(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.spacing(5),
  borderRadius: theme.spacing(0, 3, 3, 0),
  border: `1px solid ${Colors.GREY_30}`,
  borderLeft: 'none',
  backgroundColor: 'white',
  justifyContent: 'normal',
  gap: theme.spacing(5),
  width: '100%',
}));

const ErrorListItem = styled(ListItem, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: theme.spacing(3),
  padding: theme.spacing(4, 5),
  backgroundColor: severity === 'error' ? Colors.ERROR_LIGHT_BG : Colors.WARNING_LIGHT_BG,
  borderRadius: '4px',
}));

const ErrorListContainer = styled(List)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
}));

const ErrorHeader = styled(Typography, {
  shouldForwardProp: prop => prop !== 'severity',
})<{ severity: ElementErorrSeverity }>(({ severity, theme }) => ({
  color: severity === 'error' ? theme.palette.error.main : theme.palette.warning.dark,
}));
