import React from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { Link } from 'react-router-dom';

import { ExperimentsNavigation } from 'client/app/apps/experiments/ExperimentsNavigation';
import {
  useCreateProtocolAndNavigate,
  useCreateProtocolInstanceAndNavigate,
} from 'client/app/apps/protocols/api/ProtocolsAPI';
import { MockProtocols } from 'client/app/apps/protocols/useGetData';
import { protocolsRoutes } from 'client/app/lib/nav/actions';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';

export default function ProtocolsScreen() {
  return (
    <ExperimentsNavigation title="Protocols">
      <h1>List Protocols</h1>
      <h2>Use Protocols:</h2>
      <ul style={{ listStyle: 'none', padding: 0 }}>
        {MockProtocols.map(mockProtocol => {
          return (
            <li
              style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                marginBottom: '12px',
              }}
              key={mockProtocol.protocolId}
            >
              <CreateNewProtocolButton
                name={mockProtocol.name}
                workflowId={mockProtocol.workflowId as WorkflowId}
              />
              <CopyProtocolInstanceButton
                protocolId={mockProtocol.protocolId as ProtocolId}
                protocolVersion={1 as ProtocolVersion}
              />
              <Link
                key={mockProtocol.protocolId}
                to={protocolsRoutes.editProtocolInstance.getPath({
                  id: mockProtocol.protocolInstanceId,
                })}
              >
                {mockProtocol.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </ExperimentsNavigation>
  );
}

type CopyProtocolInstanceButtonProps = {
  protocolId: ProtocolId;
  protocolVersion: ProtocolVersion;
};

// TODO - Deprecate this once the protocol instance disovery page is completed.
// This is added to allow easy access copying of protocol instances.
function CopyProtocolInstanceButton({
  protocolId,
  protocolVersion,
}: CopyProtocolInstanceButtonProps) {
  const { handleCreateProtocolInstanceAndNavigate, loading } =
    useCreateProtocolInstanceAndNavigate();

  return (
    <Button
      sx={{ minWidth: '200px', borderColor: Colors.GREY_30 }}
      variant="secondary"
      onClick={() => handleCreateProtocolInstanceAndNavigate(protocolId, protocolVersion)}
    >
      {loading ? (
        <CircularProgress size={20} />
      ) : (
        'Make an editable Protocol Instance copy'
      )}
    </Button>
  );
}

type CreateNewProtocolButtonProps = {
  name: string;
  workflowId: WorkflowId;
};

function CreateNewProtocolButton({ name, workflowId }: CreateNewProtocolButtonProps) {
  const { handleCreateProtocolAndNavigate, loading } = useCreateProtocolAndNavigate();

  return (
    <Button
      sx={{ minWidth: '200px', borderColor: Colors.GREY_30 }}
      variant="secondary"
      onClick={() => handleCreateProtocolAndNavigate(name, workflowId)}
    >
      {loading ? <CircularProgress size={20} /> : 'Create new protocol'}
    </Button>
  );
}
