import { useState } from 'react';

import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { ElementDetailsTabs } from 'client/app/components/ElementDetails/ElementDetails';
import { ProtocolStep } from 'common/types/Protocol';

export const useProtocolStep = () => {
  const [tabId, setTabId] = useState(ElementDetailsTabs.INPUTS);
  const { updateStepDisplay, deleteStep, protocolSteps } = useStepsContext();
  const [activeStepId, setActiveStepId] = useState(protocolSteps[0]?.id);

  const getStepProps = (step: ProtocolStep) => {
    const active = step.id === activeStepId;
    const canDelete = protocolSteps.length > 1;
    const onDelete = () => {
      deleteStep(step.id);
      if (activeStepId === step.id) {
        const newSteps = protocolSteps.filter(items => items.id !== step.id);

        setActiveStepId(newSteps[0].id);
      }
    };

    return {
      step,
      active,
      canDelete,
      onDelete,
      onChange: updateStepDisplay,
      onActivate: () => setActiveStepId(step.id),
    };
  };

  return {
    tabId,
    setTabId,
    activeStep: protocolSteps.find(step => step.id === activeStepId),
    getStepProps,
  };
};
