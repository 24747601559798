import React from 'react';

import Tooltip from 'common/ui/components/Tooltip';
import { Permission, PermissionsContext } from 'common/ui/PermissionsProvider';

type HasPermissionProps<PermissionKeys> = {
  permission: Permission<PermissionKeys>;
  renderItem: (hasPermission: boolean) => React.ReactNode | null;
  toolTipCopy?: string;
};

export const createHasPermission = <PermissionKeys,>(
  usePermissions: () => PermissionsContext<PermissionKeys>,
) => {
  return (props: HasPermissionProps<PermissionKeys>) => {
    const { permission, renderItem, toolTipCopy } = props;
    const { currentUserHasPermission } = usePermissions();

    const hasPermission = currentUserHasPermission(permission);

    return toolTipCopy && !hasPermission ? (
      <Tooltip title={toolTipCopy}>
        <span>{renderItem(hasPermission)}</span>
      </Tooltip>
    ) : (
      <>{renderItem(hasPermission)}</>
    );
  };
};
