import React, { useCallback, useState } from 'react';

import TextField from '@mui/material/TextField';

import PolicyLibraryApp from 'client/app/apps/policy-library/PolicyLibraryApp.old';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import Policies from 'client/app/lib/policies';
import Button from 'common/ui/components/Button';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import DialogActions from 'common/ui/components/Dialog/DialogActions';
import makeStylesHook from 'common/ui/hooks/makeStylesHook';
import { DialogProps } from 'common/ui/hooks/useDialog';

// Faster lookup, with Set.prototype.has
const policiesIDs = new Set(Policies.map(p => p.id));

type Props = {
  value: string;
} & DialogProps<string | undefined | typeof CANCEL_CHOICE>;

function isCustomPolicy(policy: string) {
  return !policiesIDs.has(policy);
}

// If we have a customPolicy but no standard policy we show the custom policy message
function getSelectMessage(customPolicy: string, selectedId: string) {
  if (customPolicy !== '' && !selectedId) {
    return 'Select Custom Policy';
  } else if (selectedId) {
    return 'Select';
  }
  return 'Select None';
}
export default function PolicyDialog(props: Props) {
  const classes = useStyles();
  const { onClose, value, isOpen } = props;
  const [selectedId, setSelectedId] = useState(isCustomPolicy(value) ? '' : value);
  const [customPolicy, setCustomPolicy] = useState(isCustomPolicy(value) ? value : '');

  const onQuickPick = useCallback(
    (simulationId: string) => {
      setSelectedId(simulationId);
      setCustomPolicy('');
      onClose(simulationId);
    },
    [setSelectedId, onClose],
  );
  const onPick = useCallback(
    (simulationId: string) => {
      setCustomPolicy('');
      setSelectedId(id => {
        if (id === simulationId) {
          // unselect
          return '';
        }
        return simulationId;
      });
    },
    [setSelectedId],
  );
  const onCancel = useCallback(() => onClose(CANCEL_CHOICE), [onClose]);
  const onConfirm = useCallback(() => {
    if (customPolicy) {
      onClose(customPolicy);
    }
    onClose(selectedId);
  }, [onClose, selectedId, customPolicy]);
  const onCustomPolicyChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const newPolicy = e.target.value;
      setCustomPolicy(newPolicy);
      // We select the existing policy if the user input matches an actual policy.
      // but we do not empty the field as the user may type more.
      if (isCustomPolicy(newPolicy)) {
        setSelectedId('');
      } else {
        setSelectedId(newPolicy);
      }
    },
    [setCustomPolicy],
  );

  const selectMessage = getSelectMessage(customPolicy, selectedId);

  return (
    <ComplexActionDialog
      title="Liquid Policies"
      onClose={onCancel}
      isOpen={isOpen}
      showCloseButton
      fullHeight
      content={
        <div className={classes.dialogContent}>
          <PolicyLibraryApp
            onPick={onPick}
            onQuickPick={onQuickPick}
            selectedId={selectedId}
          />
        </div>
      }
      dialogActions={
        <DialogActions className={classes.dialogActions}>
          <div className={classes.customPolicy}>
            <TextField
              variant="standard"
              fullWidth
              label="Use Custom Policy"
              onChange={onCustomPolicyChange}
              value={customPolicy}
            />
          </div>
          <div>
            <Button variant="tertiary" onClick={onConfirm} color="primary">
              {selectMessage}
            </Button>
          </div>
        </DialogActions>
      }
    />
  );
}

const useStyles = makeStylesHook({
  customPolicy: {
    display: 'flex',
    justifyContent: 'center',
    '&>div': {
      width: '70%',
      maxWidth: '400px',
      flex: '1',
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  dialogContent: {
    height: 0,
    flexGrow: 1,
    display: 'flex',
  },
});
