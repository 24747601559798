import React from 'react';

import SvgIcon from '@mui/material/SvgIcon';

function SystemPolicyIcon(
  props: React.PropsWithChildren<Pick<React.HTMLAttributes<HTMLDivElement>, 'className'>>,
) {
  return (
    <SvgIcon viewBox="0 0 32 32" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 7.167V9.75H8.75v15.5h20.667V15H32v10.25a2.583 2.583 0 0 1-2.583 2.583H8.75a2.583 2.583 0 0 1-2.583-2.583V9.75A2.583 2.583 0 0 1 8.75 7.167H21Z"
        fill="#333"
      />
      <path
        d="M24.158 4.973v.002h.004v6.956l2.746 1.45 2.746-1.45V4.975h.003v-.001h1.294v-.637c.014.076.021.154.021.233v.404h.955v1.134h-.955v5.823c0 .49-.27.938-.703 1.166l-2.745 1.45a1.318 1.318 0 0 1-1.231 0l-2.746-1.45a1.318 1.318 0 0 1-.703-1.166V6.108h-.956V4.973h2.27Z"
        fill="#333"
      />
      <path
        d="M25.096 7.743h3.624v3.647l-1.812.966-1.812-.966V7.743ZM28.39 4.105a1.49 1.49 0 0 1-1.482 1.497 1.49 1.49 0 0 1-1.483-1.497c0-.826 1.483-3.116 1.483-3.116s1.483 2.29 1.483 3.116Z"
        fill="#333"
      />
      <path
        d="M13.13 13.542a1.152 1.152 0 0 0-1.953 1.22l4.348 6.95a1.152 1.152 0 0 0 1.953-1.221l-4.347-6.95Z"
        fill="#1670FF"
        opacity=".6"
      />
      <path
        d="M15.523 13.542a1.152 1.152 0 0 1 1.954 1.22l-4.348 6.95a1.152 1.152 0 0 1-1.954-1.22l4.348-6.95Z"
        fill="#9710FF"
        opacity=".6"
      />
      <path
        d="M18.425 15.846a1.152 1.152 0 0 1 1.954 1.22l-2.906 4.645a1.152 1.152 0 0 1-1.954-1.22l2.906-4.645Z"
        fill="#3AB83F"
        opacity=".6"
      />
      <path
        d="M17.477 13.542a1.152 1.152 0 0 0-1.954 1.22l4.348 6.95a1.152 1.152 0 0 0 1.953-1.221l-4.347-6.95Z"
        fill="#E6D220"
        opacity=".6"
      />
      <path
        d="M12.153 22.254a1.152 1.152 0 1 1 0-2.303 1.152 1.152 0 0 1 0 2.303Z"
        fill="#ED3770"
      />
      <path
        d="M12.153 15.304a1.152 1.152 0 1 1 0-2.304 1.152 1.152 0 0 1 0 2.304Z"
        fill="#1670FF"
      />
      <path
        d="M20.847 15.305A1.153 1.153 0 1 1 20.848 13a1.153 1.153 0 0 1-.001 2.305Z"
        fill="#3AB83F"
      />
      <path
        d="M20.846 22.253a1.152 1.152 0 1 1-.001-2.303 1.152 1.152 0 0 1 0 2.303Z"
        fill="#F75C03"
      />
      <path
        d="M16.541 13.012h-.094a1.148 1.148 0 0 0-.928.54l-.028.046a1.15 1.15 0 0 0 .028 1.175l.978 1.562.977-1.562c.231-.369.227-.82.029-1.174l-.029-.045a1.157 1.157 0 0 0-.933-.542Z"
        fill="#B6637A"
      />
      <path
        d="M16.48 22.26h.093c.015-.002.031-.002.046-.003h.001c.349-.03.68-.22.88-.542l.028-.046c.197-.355.2-.805-.032-1.173l-.985-1.558-.97 1.566c-.23.37-.224.82-.023 1.175l.03.046c.211.333.565.521.931.536Z"
        fill="#5AB28D"
      />
    </SvgIcon>
  );
}

export default React.memo(SystemPolicyIcon);
