import React from 'react';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';

import { useQueryProtocol } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { ProtocolProvider } from 'client/app/apps/protocols/context/ProtocolProvider';
import { StepsProvider } from 'client/app/apps/protocols/context/StepsProvider';
import { WorkflowProvider } from 'client/app/apps/protocols/context/WorkflowProvider';
import { useProtocolNavigation } from 'client/app/apps/protocols/useProtocolNavigation';
import UIErrorBox from 'client/app/components/UIErrorBox';
import Button from 'common/ui/components/Button';
import LinearProgress from 'common/ui/components/LinearProgress';

type Props = {
  id: ProtocolId;
  version: ProtocolVersion;
};

const EditProtocolScreen: React.FC<Props> = ({ id, version }: Props) => {
  const { Content, previous, next, publishProtocolDialog } = useProtocolNavigation();
  const { data, loading, error } = useQueryProtocol(id, version);

  if (error) return <UIErrorBox>Could not load protocol</UIErrorBox>;
  if (loading || !data) return <LinearProgress />;

  const { entity, elementContext } = data.protocol;
  const { workflow } = entity;
  const { Schema: schema = {} } = workflow.workflow;
  const { steps } = entity.protocol;

  return (
    <StepsProvider steps={steps} schema={schema}>
      <WorkflowProvider workflow={workflow} elementContext={elementContext}>
        <ProtocolProvider entity={entity} version={version}>
          <Content />
          {publishProtocolDialog}
          <Footer>
            <Button variant="secondary" onClick={previous.onClick}>
              {previous.label}
            </Button>
            <Button variant="primary" color="primary" onClick={next.onClick}>
              {next.label}
            </Button>
          </Footer>
        </ProtocolProvider>
      </WorkflowProvider>
    </StepsProvider>
  );
};
export default EditProtocolScreen;

const Footer = styled(Paper)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  position: 'sticky',
  bottom: 0,
  gap: theme.spacing(5),
  padding: theme.spacing(4, 6),
}));
