import React, { useState } from 'react';

import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';

import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import { useCreatorFilter } from 'client/app/apps/policy-library/filters';
import useLiquidPolicies from 'client/app/apps/policy-library/useLiquidPolicies.hook';
import LiquidPolicyCard from 'client/app/components/cards/LiquidPolicyCard/LiquidPolicyCard';
import { LiquidPolicy, LiquidPolicySourceEnum } from 'client/app/gql';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import FilterChipWithCheckbox from 'common/ui/components/FilterChip/FilterChipWithCheckbox';
import SearchField from 'common/ui/components/SearchField';
import useDebounce from 'common/ui/hooks/useDebounce';

type Props = {
  /** Name of the liquid policy set in element parameters */
  selectedPolicyName?: string | undefined;
  /** Should toggle liquid policy highlight */
  onCardClick?: (policy: LiquidPolicy) => void;
  /** Should select the liquid policy */
  onCardDoubleClick?: (policy: LiquidPolicy) => void;
  /** Should show liquid policy details view */
  onViewDetails?: (policy: LiquidPolicy) => void;
};

export default function PolicyLibraryApp({
  selectedPolicyName,
  onCardClick,
  onCardDoubleClick,
  onViewDetails,
}: Props) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>();
  const handleQueryChange = useDebounce(setSearchQuery, 200);

  const creatorFilter = useCreatorFilter();

  const liquidPolicies = useLiquidPolicies(searchQuery, creatorFilter);

  const isSelected = (policy: LiquidPolicy) =>
    selectedPolicyName?.toLowerCase() === policy.name.toLowerCase();

  return (
    <Stack flexGrow={1} pt={5}>
      <ContainerWithIntersectionBar
        headerLeftContent={
          <Stack direction="row" py={5}>
            <FilterChipWithCheckbox<LiquidPolicySourceEnum>
              heading="Filter by Creator"
              defaultChipLabel="Policy Creator"
              filterValue={creatorFilter.value}
              onFilter={creatorFilter.onChange}
            />
          </Stack>
        }
        headerRightContent={
          <Stack py={5}>
            <SearchField
              onQueryChange={handleQueryChange}
              placeholder="Search"
              addMargin
            />
          </Stack>
        }
        content={
          liquidPolicies.loading ? (
            <LinearProgress />
          ) : (
            <Stack gap={6} px={8} py={6}>
              {liquidPolicies.total === 0 && (
                <NoEntitiesMessage
                  entityName="liquid policies"
                  messageType={MessageType.NO_FILTER_RESULTS}
                  searchQuery={searchQuery}
                />
              )}
              <Stack direction="row" gap={6} flexWrap="wrap">
                {liquidPolicies.custom.map(policy => (
                  <LiquidPolicyCard
                    key={policy.id}
                    policy={policy}
                    selected={isSelected(policy)}
                    onClick={onCardClick}
                    onDoubleClick={onCardDoubleClick}
                    onViewDetails={onViewDetails}
                    // TODO: handle policy edit and remove (for custom policies ONLY)
                  />
                ))}
              </Stack>
              <Stack direction="row" gap={6} flexWrap="wrap">
                {liquidPolicies.system.map(policy => (
                  <LiquidPolicyCard
                    key={policy.id}
                    policy={policy}
                    selected={isSelected(policy)}
                    onClick={onCardClick}
                    onDoubleClick={onCardDoubleClick}
                    onViewDetails={onViewDetails}
                  />
                ))}
              </Stack>
            </Stack>
          )
        }
      />
    </Stack>
  );
}
