import React from 'react';

import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';

import EditableName, { Name } from 'client/app/apps/protocols/StepCard/EditableName';
import StepContents from 'client/app/apps/protocols/StepCard/StepContents';
import { ElementDetailsTabs } from 'client/app/components/ElementDetails/ElementDetails';
import { ProtocolStep } from 'common/types/Protocol';
import Colors from 'common/ui/Colors';
import Tabs, { TabsInfo } from 'common/ui/components/Tabs';

type Props = {
  /**
   * If true, shows the step as selected an shows information about
   * the inputs and outputs
   */
  active?: boolean;
  /**
   * called when an inactive step is activated
   */
  onActivate: () => void;
  step: ProtocolStep;
  tabId: ElementDetailsTabs;
  /**
   * called when a tab is changed
   */
  setTabId: (tabId: ElementDetailsTabs) => void;
  /**
   * called when a step, or one of its inputs or outputs, is modified
   */
  onChange: (newStep: ProtocolStep) => void;
  canDelete?: boolean;
  /**
   * called when a step is deleted
   */
  onDelete: () => void;
  /**
   * called when a step input is deleted
   */
  onDeleteInput?: (index: number) => void;
  /**
   * called when a step output is deleted
   */
  onDeleteOutput?: (index: number) => void;
};

/**
 * StepCard displays info about a ProtocolStep during its creation
 */
export default function StepCard(props: Props) {
  const {
    step,
    tabId,
    setTabId,
    active,
    onActivate,
    onChange,
    canDelete,
    onDelete,
    onDeleteInput,
    onDeleteOutput,
  } = props;

  const handleUpdate = (updates: Partial<ProtocolStep>) => {
    onChange({
      ...step,
      ...updates,
    });
  };

  return (
    <StyledCard active={active} onClick={active ? undefined : onActivate}>
      {active ? (
        <EditableName
          name={step.displayName}
          onSave={displayName => handleUpdate({ displayName })}
          canDelete={canDelete}
          onDelete={onDelete}
        />
      ) : (
        <Name>{step.displayName}</Name>
      )}
      <Collapse in={active}>
        <Stack gap={4}>
          <StepTabs
            tabsInfo={TABS}
            activeTab={tabId}
            minimumTabWidth={MIN_TAB_WIDTH}
            onChangeTab={setTabId}
          />
          {tabId === ElementDetailsTabs.INPUTS && (
            <StepContents
              items={step.inputs}
              emptyMessage="Select a parameter from the elements in the workflow."
              onUpdate={inputs => handleUpdate({ inputs })}
              onDelete={onDeleteInput}
            />
          )}
          {tabId === ElementDetailsTabs.OUTPUTS && (
            <StepContents
              items={step.outputs}
              emptyMessage="Select an output from the elements in the workflow."
              onUpdate={outputs => handleUpdate({ outputs })}
              onDelete={onDeleteOutput}
            />
          )}
        </Stack>
      </Collapse>
    </StyledCard>
  );
}

const StyledCard = styled(Paper, { shouldForwardProp: prop => prop !== 'active' })<{
  active?: boolean;
}>(({ active, theme: { spacing, palette } }) => ({
  border: active ? `1px solid ${palette.primary.main}` : 'unset',
  backgroundColor: active ? Colors.BLUE_0 : 'unset',
  '&:hover, &:focus': {
    backgroundColor: Colors.BLUE_0,
  },
  cursor: active ? 'unset' : 'pointer',
  borderRadius: '4px',
  padding: spacing(5),
  display: 'flex',
  flexDirection: 'column',
}));

const MIN_TAB_WIDTH = '138px';
const TABS: TabsInfo<ElementDetailsTabs> = [
  { value: ElementDetailsTabs.INPUTS, label: 'Parameters' },
  { value: ElementDetailsTabs.OUTPUTS, label: 'Outputs' },
];

const StepTabs = styled(Tabs)({
  '& button': {
    maxWidth: 'unset',
    flex: 1,
  },
}) as typeof Tabs;
