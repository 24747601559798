import { Markdown } from 'common/lib/markdown';
import { Condition, Rule } from 'common/rules/types';
import { ParameterValue } from 'common/types/bundle';
import {
  ConfigurationType,
  GlobalFileObject,
  ParameterEditorConfigurationSpec,
} from 'common/types/commonConfiguration';
import { OpaqueAlias } from 'common/types/OpaqueAlias';

export type ElementConfigurationID = string;
export type ElementName = string;
export type ParameterName = OpaqueAlias<string, 'Parameter.name'>;

/** Overrides and settings for an element. */
export type ElementConfigurationSpec = {
  elementDisplayName: string;
  elementDisplayDescription: Markdown;
  parameters: {
    [parameterName: string]: ParameterConfigurationSpec;
  };

  /**
   * List of parameter groups for element inputs. This defines order of groups and
   * parameters inside them.
   */
  inputGroups: ParameterGroupConfigurationSpec[];
  /** Order of output parameters. */
  outputOrder: string[];
  /** List of rules applied when editing workflow. */
  rules?: ElementConfigurationRule[];
};

/** Overrides and settings for an element parameter. */
export type ParameterConfigurationSpec = {
  displayName: string;
  displayDescription: Markdown;
  editor: ParameterEditorConfigurationSpec;
  isVisible: boolean;
  isConnectable?: boolean;
  isInternalOnly?: boolean;
  isDOEable?: boolean;
  /*
   * true if the parameter should not be exposed as part of
   * a workflow schema, e.g. for Protocols.
   * If undefined or false, then the parameter may be used
   */
  isBlockedFromSchema?: boolean;
  defaultValue?: ParameterValue | GlobalFileObject;
  connections?: ConnectionConfiguration;
  shortDescription?: string;
};

/**
 * Information used to determine which element ports can be connected to each
 * other in the UI.
 * */
type ConnectionConfiguration = {
  /**
   * A list of port types that can be wired into that port.
   * This should only be set through the type configuration tool and should not
   * include the type of the port it pertains to.
   **/
  allowedTypes?: string[];
  // TODO: Add parameter-level connection settings. (T2716)
};

/** Overrides and settings for an element input group. */
export type ParameterGroupConfigurationSpec = {
  groupName: string;
  groupDescription?: string;
  parameterNames: string[];
};

type DateTimeISO8601 = string;

/** Structure sent from admin to appservers to replicate element configurations. */
export type ElementConfigurationDiff = {
  elementName: ElementName;
  upsert: {
    id: ElementConfigurationID;
    elementName: ElementName;
    spec: ElementConfigurationSpec;
    commitHash: string;
    commitDate: DateTimeISO8601;
    commitBranch: string;
    configurationType: ConfigurationType;
  }[];
  delete: ElementConfigurationID[];
  deleteAllOthers: boolean;
};

export type ElementConfigurationRule = Rule<
  ElementConfigurationCondition,
  ElementConfigurationAction
>;

export type ElementConfigurationAction =
  | SetBooleanFieldAction
  | AddParameterMessageAction;

export type SetBooleanFieldAction =
  | SetParametersRequiredAction
  | SetParametersNotRequiredAction
  | SetParametersVisibleAction
  | SetParametersNotVisibleAction
  | SetParametersEnabledAction
  | SetParametersNotEnabledAction;

type SetParametersVisibleAction = {
  type: 'set-parameters-visible';
  revertIfConditionNotMet: boolean;
  parameterNames: string[];
};

type SetParametersNotVisibleAction = {
  type: 'set-parameters-not-visible';
  revertIfConditionNotMet: boolean;
  parameterNames: string[];
};

export type SetParametersRequiredAction = {
  type: 'set-parameters-required';
  revertIfConditionNotMet: boolean;
  parameterNames: string[];
};

type SetParametersNotRequiredAction = {
  type: 'set-parameters-not-required';
  revertIfConditionNotMet: boolean;
  parameterNames: string[];
};

type SetParametersEnabledAction = {
  type: 'set-parameters-enabled';
  revertIfConditionNotMet: boolean;
  parameterNames: string[];
};

type SetParametersNotEnabledAction = {
  type: 'set-parameters-not-enabled';
  revertIfConditionNotMet: boolean;
  parameterNames: string[];
};

export type AddParameterMessageAction =
  | {
      type: 'add-parameter-error';
      parameterName: string;
      errorMessage: string;
    }
  | {
      type: 'add-parameter-warning';
      parameterName: string;
      message: string;
    };

// Rule conditions
export type ElementConfigurationCondition = Condition<
  | AreParametersSetCondition
  | AreParametersConnectedCondition
  | ParameterValueCompareCondition
>;

export type ConditionType = ElementConfigurationCondition['type'];

export type AreParametersSetCondition = {
  type: 'are-parameters-set';
  parameterNames: string[];
};

export type AreParametersConnectedCondition = {
  type: 'are-parameters-connected';
  parameterNames: string[];
};

export type ParameterValueCompareCondition = {
  type: 'parameter-value-compare';
  parameterName: string;
  operator:
    | 'equals'
    | 'not-equals'
    | 'greater'
    | 'greater-or-equal'
    | 'less'
    | 'less-or-equal';
  value: ValueExpression;
};

/**
 * A discriminated union type, include a 'type' property for any additions
 * to the union.
 */
export type ValueExpression = ValueExpressionConst | ValueExpressionParameter;

type ValueExpressionConst = { type: 'const'; constValue: any };
type ValueExpressionParameter = { type: 'parameter'; parameterName: string };

export const isConstValueType = (
  value: ValueExpression,
): value is ValueExpressionConst => {
  return value.type === 'const';
};

export const isParameterValueType = (
  value: ValueExpression,
): value is ValueExpressionParameter => {
  return value.type === 'parameter';
};

/** The type of the response from the appserver commit history list endpoint */
export type AppserverCommitHistoryAPIResponse = {
  commits: AppserverCommit[];
};

/**
 * The type of commits included in the appserver commit history list endpoint.
 * The property names are slightly different to those of Commit, hence the
 * specific type here.
 */
export type AppserverCommit = {
  commit_hash: string;
  commit_date: string;
  commit_branch: string;
};
