import React from 'react';

import { styled } from '@mui/material/styles';

import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';

export const PersonaliseProtocol = () => {
  return (
    <Content>
      <ContainerWithIntersectionBar
        dense
        noHeader
        content={
          <div>
            <h1>Personalise protocol</h1>
          </div>
        }
      />
    </Content>
  );
};

const Content = styled('div')(() => ({
  display: 'flex',
  height: '100%',
}));
