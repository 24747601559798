import React, { useCallback } from 'react';

import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { useProtocolInstanceContext } from 'client/app/apps/protocols/context/ProtocolInstanceProvider';
import { useSimulationContext } from 'client/app/apps/protocols/context/SimulationProvider';
import { useStepsContext } from 'client/app/apps/protocols/context/StepsProvider';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { useStepErrors } from 'client/app/apps/protocols/lib/utils';
import FabWithFX from 'client/app/apps/workflow-builder/FabWithFX';
import { ProtocolValidationIndicatorWithPopover } from 'client/app/components/ValidationIndicator/ValidationIndicator';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { ErrorCodes } from 'common/types/errorCodes';
import { ProtocolStep } from 'common/types/Protocol';
import Colors from 'common/ui/Colors';
import Tooltip from 'common/ui/components/Tooltip';
import TypographyWithTooltip from 'common/ui/components/TypographyWithTooltip';
import { SimulationIcon } from 'common/ui/icons';
import { TemplateWorkflowIcon } from 'common/ui/icons/TemplateWorkflow';

export const EditProtocolInstanceHeader = () => {
  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();
  const { loading: workflowLoading } = useWorkflowContext();
  const { protocolInstance, loading: protocolInstanceLoading } =
    useProtocolInstanceContext();
  const {
    onSimulate,
    isSimulating,
    simulateButtonTooltipTitle,
    shouldDisableSimulateButton,
  } = useSimulationContext();
  const { protocolSteps, handleSelectStep } = useStepsContext();
  const stepErrors = useStepErrors(protocolSteps);
  const anyErrorsInAllSteps = Object.values(stepErrors).some(errors =>
    errors.some(({ severity }) => severity === 'error'),
  );

  const handleValidationErrorClick = useCallback(
    (erroredElementId: string) => {
      const erroredStep = findErroredStep(protocolSteps, erroredElementId);
      erroredStep && handleSelectStep(erroredStep);
    },
    [handleSelectStep, protocolSteps],
  );

  const formatErrorTitle = useCallback(
    (erroredElementId: string) => {
      const erroredStep = findErroredStep(protocolSteps, erroredElementId);
      return erroredStep?.displayName || '';
    },
    [protocolSteps],
  );

  const handleOnSimulate = useCallback(async () => {
    try {
      await onSimulate();
    } catch (error) {
      await handleCheckConflictError(
        error,
        'protocol',
        ErrorCodes.PROTOCOL_INSTANCE_EDIT_CONFLICT,
      );
    }
  }, [handleCheckConflictError, onSimulate]);

  const protocolInstanceName = protocolInstance.name;

  // TODO: It is not clear yet how are we going to handle non editable behaviours
  const isReadonly = false;
  const isLoading = workflowLoading || protocolInstanceLoading;

  return (
    <Wrapper>
      <StyledTemplateWorkflowIcon />
      <TypographyWrapper>
        <ProtocolHeader variant="h4">{protocolInstanceName}</ProtocolHeader>
        <Typography variant="body2">Modify the inputs of your protocol</Typography>
      </TypographyWrapper>

      <RightContentWrapper>
        <StatusButtonsWrapper>
          <ProtocolValidationIndicatorWithPopover
            isLoading={isLoading}
            onClickElementError={handleValidationErrorClick}
            formatErrorTitle={formatErrorTitle}
          />
        </StatusButtonsWrapper>
        <Tooltip title={simulateButtonTooltipTitle}>
          <FabWithFX
            busy={isSimulating}
            color="secondary"
            onClick={handleOnSimulate}
            size="medium"
            disabled={
              isReadonly ||
              isLoading ||
              anyErrorsInAllSteps ||
              shouldDisableSimulateButton
            }
            variant="extended"
          >
            <StyledIcon />
            Simulate
          </FabWithFX>
        </Tooltip>
      </RightContentWrapper>
      {conflictDialog}
    </Wrapper>
  );
};

const findErroredStep = (steps: ProtocolStep[], erroredElementId: string) => {
  return steps.find(step =>
    step.inputs.some(({ elementInstanceId }) => elementInstanceId === erroredElementId),
  );
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flex: 1,
  justifyContent: 'start',
  alignItems: 'center',
  gap: theme.spacing(4),
  borderBottom: `1px solid ${Colors.GREY_30}`,
  padding: theme.spacing(4, 6),
  minHeight: '70px',
}));

const TypographyWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}));

const StyledIcon = styled(SimulationIcon)(({ theme }) => ({
  marginRight: theme.spacing(3),
}));

const ProtocolHeader = styled(TypographyWithTooltip)(() => ({
  fontWeight: 600,
}));

const StyledTemplateWorkflowIcon = styled(TemplateWorkflowIcon)(() => ({
  color: '#1773C9',
}));

const RightContentWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  marginLeft: 'auto',
  alignItems: 'center',
}));

const StatusButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(6),
}));
