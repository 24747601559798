import React, { useState } from 'react';

import { styled } from '@mui/material/styles';

import {
  MessageType,
  NoEntitiesMessage,
} from 'client/app/apps/experiments/NoEntitiesMessage';
import PolicyCard from 'client/app/apps/policy-library/PolicyCard.old';
import { LiquidPolicy } from 'client/app/gql';
import Policies from 'client/app/lib/policies';
import Colors from 'common/ui/Colors';
import CardGrid from 'common/ui/components/CardGrid';
import ContainerWithIntersectionBar from 'common/ui/components/ContainerWithIntersectionBar/ContainerWithIntersectionBar';
import SearchField from 'common/ui/components/SearchField';

type Props = {
  onPick?: (policy: string) => void;
  onQuickPick?: (policy: string) => void;
  /**
   * `selectedId` is not a UUID it is the name of the policy
   * from the policy library as before these policies were hardcoded in `policies.ts`
   * in a file.
   *
   * Ultimately, we might want to move to UUIDs from the DB for liquid policies.
   */
  selectedId?: string;
};

export default function PolicyLibraryApp(props: Props) {
  const { onPick, selectedId, onQuickPick } = props;

  const [filterQuery, setFilterQuery] = useState('');

  const renderedItems = computeFilteredPolicies(Policies, filterQuery).map(
    (item, index) => (
      <PolicyCard
        key={index}
        onPick={onPick}
        onQuickPick={onQuickPick}
        policy={item}
        selected={selectedId === item.id}
      />
    ),
  );

  return (
    <Container>
      <ContainerWithIntersectionBar
        headerRightContent={
          <SearchField onQueryChange={setFilterQuery} placeholder="Search" addMargin />
        }
        content={
          <CardGrid>
            {filterQuery !== '' && renderedItems.length === 0 && (
              <NoEntitiesMessage
                entityName="liquid policies"
                messageType={MessageType.NO_FILTER_RESULTS}
                searchQuery={filterQuery}
              />
            )}
            {renderedItems}
          </CardGrid>
        }
      />
    </Container>
  );
}

function computeFilteredPolicies<
  T extends Pick<LiquidPolicy, 'id' | 'name' | 'description'>,
>(policiesArray: T[], filterQuery: string): T[] {
  const lowerCaseQuery = filterQuery.toLowerCase();

  return policiesArray.filter(policy => {
    const { name, description, id } = policy;
    return (
      name.toLowerCase().includes(lowerCaseQuery) ||
      description?.toLowerCase().includes(lowerCaseQuery) ||
      id.toLowerCase().includes(lowerCaseQuery)
    );
  });
}

const Container = styled('div')({
  background: Colors.GREY_5,
  flex: 1,
  overflow: 'hidden',
});
