import React, { createContext, FC, useContext, useEffect, useState } from 'react';

import { useUpdateProtocolInstance } from 'client/app/apps/protocols/api/ProtocolsAPI';
import { useWorkflowContext } from 'client/app/apps/protocols/context/WorkflowProvider';
import { ProtocolInstanceQuery } from 'client/app/gql';
import useEntityConflictErrorDialog from 'client/app/hooks/useEntityConflictErrorDialog';
import { ErrorCodes } from 'common/types/errorCodes';

type ProtocolInstanceContextType = {
  loading: boolean;
  protocolInstance: NonNullable<ProtocolInstanceQuery['protocolInstance']['instance']>;
  updateProtocolInput: (schemaInputId: string, value: any) => void;
  updateConflictDialog: JSX.Element | null;
};

export const ProtocolInstanceContext = createContext<
  ProtocolInstanceContextType | undefined
>(undefined);

type ProtocolInstanceProviderProps = {
  instance: NonNullable<ProtocolInstanceQuery['protocolInstance']['instance']>;
};

export const useProtocolInstanceContext = () => {
  const context = useContext(ProtocolInstanceContext);

  if (context === undefined) {
    throw new Error(
      'useProtocolInstanceContext must be used within a ProtocolInstanceProvider',
    );
  }

  return context;
};

export const ProtocolInstanceProvider: FC<ProtocolInstanceProviderProps> = ({
  instance,
  children,
}) => {
  const { updateInput: updateWorkflowInput, updateElementContext } = useWorkflowContext();
  const { handleCheckConflictError, conflictDialog } = useEntityConflictErrorDialog();
  const [protocolInputs, setProtocolInputs] = useState(instance.parameters);
  const [updateInstanceRequired, setUpdateInstanceRequired] = useState(false);
  const { handleUpdateProtocolInstance, loading } = useUpdateProtocolInstance(
    instance.id,
  );

  // always ensure protocol inputs override default parameter values first so
  // the users get real time updates
  useEffect(() => {
    Object.entries(protocolInputs).forEach(([id, value]) => {
      updateWorkflowInput(id, value);
    });
  }, [protocolInputs, updateWorkflowInput]);

  // trigger instance updates only if one is not already happening
  useEffect(() => {
    (async () => {
      if (loading || !updateInstanceRequired || conflictDialog) return;

      // we are about to update so set the flag false. While the updating
      // the user may make other edits that toggle the flag true
      setUpdateInstanceRequired(false);
      try {
        const params = { params: protocolInputs };
        const resp = await handleUpdateProtocolInstance(instance.editVersion, params);
        updateElementContext(resp);
      } catch (error) {
        await handleCheckConflictError(
          error,
          'protocol',
          ErrorCodes.PROTOCOL_INSTANCE_EDIT_CONFLICT,
        );
      }
    })();
  }, [
    conflictDialog,
    handleCheckConflictError,
    handleUpdateProtocolInstance,
    instance.editVersion,
    loading,
    protocolInputs,
    updateElementContext,
    updateInstanceRequired,
  ]);

  const updateProtocolInput = (schemaInputId: string, value: any) => {
    // important to set null so the protocol input key is retained and set with
    // a null value during serialisation, otherwise the value is not deleted
    // from the perspective of validating instance updates
    setProtocolInputs({ ...protocolInputs, [schemaInputId]: value ? value : null });
    setUpdateInstanceRequired(true);
  };

  const state = {
    loading,
    protocolInstance: instance,
    updateProtocolInput,
    updateConflictDialog: conflictDialog,
  };

  return (
    <ProtocolInstanceContext.Provider value={state}>
      {children}
    </ProtocolInstanceContext.Provider>
  );
};
