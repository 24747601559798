import React from 'react';

/**
 * One link to point to the Liquid Policies Intercom article.
 */
export default function LiquidPolicyIntercomLink() {
  return (
    <a
      href="https://app.intercom.com/a/apps/gpkq63j0/knowledge-hub/article/10743708"
      target="_blank"
      rel="noopener noreferrer"
      style={{ marginLeft: 2 }}
    >
      here
    </a>
  );
}
