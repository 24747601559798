import React, { createContext, useContext, useReducer } from 'react';

import produce from 'immer';

import {
  getColumnDataType,
  getRuleValueEditorConfig,
  getTableStateFromConfig,
  initDataTable,
  initTableConfig,
  makeColumnConfig,
  RuleMetaDataType,
} from 'client/app/apps/policy-library/components/ruleTableConfig';
import { validateDialogState } from 'client/app/apps/policy-library/components/validation';
import {
  getRuleMeta,
  RuleMetaKey,
  RuleMetaType,
} from 'client/app/apps/policy-library/RulesMeta';
import { capitalize } from 'common/lib/format';
import { SheetState } from 'common/rules/evaluateSpreadsheetRules';
import { CellValue, DataTable } from 'common/types/spreadsheetEditor';
import { TableConfiguration } from 'common/ui/components/Table';

const initState: State = {
  policy: {
    name: {
      value: undefined,
      error: undefined,
    },
    description: {
      value: undefined,
      error: undefined,
    },
    rules: {
      tableConfig: initTableConfig,
      dataTable: initDataTable,
      tableState: getTableStateFromConfig(initTableConfig),
    },
  },
  dialog: {
    open: false,
    activeTab: 0,
    rulesTab: { disabled: true },
    addPolicyButton: { disabled: true },
  },
};

const CreatePolicyStateContext = createContext<[State, React.Dispatch<Action>]>([
  initState,
  () => {
    throw new Error('[CreatePolicyStateContext]: Reducer not implemented');
  },
]);

export default function CreatePolicyStateProvider({
  children,
}: {
  children: React.ReactNode | React.ReactNode[];
}) {
  return (
    <CreatePolicyStateContext.Provider value={useReducer(reducer, initState)}>
      {children}
    </CreatePolicyStateContext.Provider>
  );
}

export const useCreatePolicyState = () => useContext(CreatePolicyStateContext);

export type State = {
  policy: {
    name: {
      value?: string;
      error?: string;
    };
    description: {
      value?: string;
      error?: string;
    };
    rules: {
      dataTable: DataTable;
      tableConfig: TableConfiguration;
      tableState: SheetState;
    };
  };
  dialog: {
    open: boolean;
    activeTab: number;
    rulesTab: { disabled: boolean };
    addPolicyButton: { disabled: boolean };
  };
};

type Action =
  | { type: 'open_dialog' }
  | { type: 'close_dialog' }
  | {
      type: 'set_active_tab';
      payload: number;
    }
  | {
      type: 'change_policy_name';
      payload: string | undefined;
    }
  | {
      type: 'change_policy_description';
      payload: string | undefined;
    }
  | {
      type: 'validate_policy_name';
      payload: string | undefined;
    }
  | {
      type: 'validate_policy_description';
    }
  | {
      type: 'update_table';
      payload: DataTable;
    }
  | {
      type: 'add_column';
      payload: {
        name: RuleMetaKey;
        type: RuleMetaType;
      };
    }
  | {
      type: 'add_row';
      payload: number;
    }
  | { type: 'reset_state' };

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'open_dialog':
      return produce(state, draft => {
        draft.dialog.open = true;
      });
    case 'close_dialog':
      return produce(state, draft => {
        draft.dialog.open = false;
      });
    case 'set_active_tab':
      return produce(state, draft => {
        draft.dialog.activeTab = action.payload;
      });
    case 'change_policy_name':
      return produce(state, draft => {
        const name = action.payload;
        draft.policy.name.value = name;
      });
    case 'change_policy_description':
      return produce(state, draft => {
        const description = action.payload;
        draft.policy.description.value = description;
      });
    case 'validate_policy_name':
      return produce(state, draft => {
        const error = action.payload;
        draft.policy.name.error = error;

        draft.dialog = validateDialogState(draft);
      });
    case 'validate_policy_description':
      return produce(state, draft => {
        const description = state.policy.description.value;

        let error: string | undefined;
        if (description?.trim() === '') {
          error =
            'Description cannot be empty. Please, provide a description of your liquid policy.';
        }
        draft.policy.description.error = error;

        draft.dialog = validateDialogState(draft);
      });
    case 'update_table':
      return produce(state, draft => {
        draft.policy.rules.dataTable = action.payload;
        draft.dialog = validateDialogState(draft);
      });
    case 'add_column': {
      const { name, type } = action.payload;
      const meta = getRuleMeta(type, name);
      const metaType = meta.type as RuleMetaDataType;

      if (state.policy.rules.dataTable.schema.fields.some(field => field.name === name)) {
        return state;
      }

      return produce(state, draft => {
        const table = draft.policy.rules.dataTable;
        const config = draft.policy.rules.tableConfig;

        // Add column to schema
        table.schema.fields.push({
          name,
          type: getColumnDataType(metaType),
        });

        // Add row if there were no rows before
        if (table.data.length === 0) {
          table.data.push({});
        }

        // Initialise column value in each row
        for (const row of table.data) {
          row[name] = null;
        }

        // Add column configuration
        config.columns.push(
          makeColumnConfig({
            name,
            displayName: `${capitalize(meta.label)} (${type})`,
            description: meta.description,
            editor: getRuleValueEditorConfig(metaType),
            dataType: getColumnDataType(metaType),
          }),
        );

        // Update table state
        draft.policy.rules.tableState = getTableStateFromConfig(config);
      });
    }
    case 'add_row':
      return produce(state, draft => {
        const table = draft.policy.rules.dataTable;
        const newRow: Record<string, CellValue> = {};
        const newRowIndex = action.payload + 1;

        table.schema.fields.forEach(field => {
          if (field.name === 'rule_name') {
            newRow['rule_name'] = `Rule_${newRowIndex}`;
          } else {
            newRow[field.name] = null;
          }
        });
        table.data.push(newRow);
      });
    case 'reset_state':
      return produce(state, draft => {
        draft.policy = initState.policy;
        draft.dialog = initState.dialog;
      });
    default:
      return state;
  }
}
