import { useMemo } from 'react';

import { useQuery } from '@apollo/client';

import { QUERY_LIQUID_POLICIES } from 'client/app/api/gql/queries';
import {
  byCreator,
  bySearchQuery,
  CreatorFilterType,
} from 'client/app/apps/policy-library/filters';
import { LiquidPolicy, LiquidPolicySourceEnum } from 'client/app/gql';

/**
 * Loads liquid policies and segregates them into 2 arrays: 'system' and 'user'.
 * This is because by design user policies should always be rendered first.
 */
export default function useLiquidPolicies(
  searchQuery?: string,
  creatorFilter?: CreatorFilterType,
) {
  const { data, loading } = useQuery<{ liquidPolicies: LiquidPolicy[] }>(
    QUERY_LIQUID_POLICIES,
    {
      fetchPolicy: 'cache-first',
    },
  );

  const [customPolicies, systemPolicies] = useMemo(() => {
    const customPolicies: LiquidPolicy[] = [];
    const systemPolicies: LiquidPolicy[] = [];

    data?.liquidPolicies
      .filter(bySearchQuery(searchQuery))
      .filter(byCreator(creatorFilter?.value))
      .forEach((policy: LiquidPolicy) => {
        if (policy.source === LiquidPolicySourceEnum.system) {
          systemPolicies.push(policy);
        } else {
          customPolicies.push(policy);
        }
      });

    return [customPolicies, systemPolicies];
  }, [creatorFilter, data?.liquidPolicies, searchQuery]);

  return {
    loading,
    total: customPolicies.length + systemPolicies.length,
    custom: customPolicies,
    system: systemPolicies,
  };
}
