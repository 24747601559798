import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import {
  addGroupPadding,
  ELEMENT_INSTANCE_WIDTH,
  getLayoutDimensions,
} from 'client/app/lib/layout/LayoutHelper';
import {
  useWorkflowBuilderDispatch,
  useWorkflowBuilderSelector,
} from 'client/app/state/WorkflowBuilderStateContext';
import stopPropagation from 'common/lib/stopPropagation';
import { Group } from 'common/types/bundle';
import { Position2d } from 'common/types/Position';
import ZoomContext from 'common/ui/components/Workspace/ZoomContext';
import useThrottle from 'common/ui/hooks/useThrottle';
import { getPosFromEvent, isDrag, isLeftMouseClick } from 'common/ui/lib/ClickRecognizer';

export type ResizeDelta = { x: number; y: number; width: number; height: number };
const ZERO_DELTA = { x: 0, y: 0, width: 0, height: 0 };
type ResizeDirection = 'n' | 's' | 'w' | 'e' | 'ne' | 'nw' | 'se' | 'sw';

export default function useGroupResize(group: Group, isSelected: boolean) {
  const { resizeDelta, resizeByDirection, updateGroupDimensions } =
    useResizeByDirection(group);

  const zoom = useContext(ZoomContext);

  const clickPosition = useRef<Position2d | null>(null);
  const resizeDirection = useRef<ResizeDirection | null>(null);
  const target = useRef<HTMLElement | null>(null);

  const onPointerDown = useCallback(
    (event: React.PointerEvent<HTMLElement>, direction: ResizeDirection) => {
      if (!isLeftMouseClick(event)) return;

      stopPropagation(event);
      clickPosition.current = getPosFromEvent(event);
      resizeDirection.current = direction;

      // Redirect all pointer events to this element to ensure that no other
      // component may intercept future drag events
      // This capture is released automatically when the pointer up event is fired.
      event.currentTarget.setPointerCapture(event.pointerId);
      target.current = event.currentTarget;
    },
    [],
  );
  const handleGroupPointerUp = useCallback(
    (event: globalThis.PointerEvent) => {
      event.stopPropagation();

      if (!resizeDirection.current) return;
      if (isDrag(clickPosition.current, event)) {
        updateGroupDimensions();
      }

      clickPosition.current = null;
      resizeDirection.current = null;
      target.current = null;
    },
    [updateGroupDimensions],
  );
  const handleGroupPointerMove = useThrottle(
    useCallback(
      (event: globalThis.PointerEvent) => {
        event.stopPropagation();

        const dragStart = clickPosition.current;
        const resizeDir = resizeDirection.current;

        if (!dragStart || !resizeDir) return;

        // In case pointer capture failed somehow and we missed a pointer up event,
        // check if the user still has the mouse button held down, and stop
        // dragging if not.
        if (isLeftMouseClick(event)) {
          target.current?.releasePointerCapture(event.pointerId);
          handleGroupPointerUp(event);
          return;
        }

        const currentPosition = getPosFromEvent(event);
        const dx = (currentPosition.x - dragStart.x) / zoom;
        const dy = (currentPosition.y - dragStart.y) / zoom;
        resizeByDirection(resizeDir, dx, dy);
      },
      [handleGroupPointerUp, resizeByDirection, zoom],
    ),
    50,
  );

  useEffect(() => {
    if (!isSelected) return;

    window.addEventListener('pointermove', handleGroupPointerMove);
    window.addEventListener('pointerup', handleGroupPointerUp);

    return () => {
      window.removeEventListener('pointermove', handleGroupPointerMove);
      window.removeEventListener('pointerup', handleGroupPointerUp);
    };
  }, [handleGroupPointerMove, handleGroupPointerUp, isSelected]);

  return { resizeDelta, onPointerDown };
}

function useResizeByDirection(group: Group) {
  const dispatch = useWorkflowBuilderDispatch();
  /**
   * We want to restrict resizing so that resized group is not smaller than
   * group elements bounding box. This allows to skip handling the case
   * when element is left behind during resize.
   */
  const { maxX, maxY, minW, minH } = useGroupBoundingBox(group);

  const [resizeDelta, setResizeDelta] = useState<ResizeDelta>(ZERO_DELTA);
  /**
   * We want to have interactive resizing so ElementGroup has to re-render on resize.
   * However, we don't want to attach/detach window event handlers on every re-render.
   * To achieve this we must skip passing 'resizeDelta' state to dependency arrays.
   * For this we store 'dimensions' copy in ref.
   */
  const deltaCopy = useRef<ResizeDelta>(ZERO_DELTA);

  const resizeByDirection = useCallback(
    (resizeDir: ResizeDirection, dx: number, dy: number) => {
      const { x, y, width, height } = group.Meta;

      const update = calcResizeByDirection(
        resizeDir,
        dx,
        dy,
        x,
        y,
        width,
        height,
        maxX,
        maxY,
        minW,
        minH,
      );
      const newDelta = {
        x: update.x ? update.x - x : 0,
        y: update.y ? update.y - y : 0,
        width: update.width ? update.width - width : 0,
        height: update.height ? update.height - height : 0,
      };
      setResizeDelta(newDelta);
      deltaCopy.current = newDelta;
    },
    [group.Meta, maxX, maxY, minW, minH],
  );
  const updateGroupDimensions = useCallback(() => {
    dispatch({
      type: 'updateElementGroup',
      payload: {
        id: group.id,
        Meta: {
          ...group.Meta,
          x: group.Meta.x + deltaCopy.current.x,
          y: group.Meta.y + deltaCopy.current.y,
          width: group.Meta.width + deltaCopy.current.width,
          height: group.Meta.height + deltaCopy.current.height,
        },
      },
    });
    setResizeDelta(state => ({ ...state, ...ZERO_DELTA }));
    deltaCopy.current = { ...deltaCopy.current, ...ZERO_DELTA };
  }, [dispatch, group.Meta, group.id]);

  return {
    resizeDelta,
    resizeByDirection,
    updateGroupDimensions,
  };
}

function useGroupBoundingBox(group: Group) {
  const allElements = useWorkflowBuilderSelector(state => state.elementInstances);
  const allConnections = useWorkflowBuilderSelector(state => state.InstancesConnections);
  const {
    left: maxX,
    top: maxY,
    width: minW,
    height: minH,
  } = useMemo(
    () =>
      addGroupPadding(
        getLayoutDimensions(
          allElements.filter(ei => group.elementIds.includes(ei.Id)),
          allConnections,
        ),
      ),
    [allConnections, allElements, group.elementIds],
  );
  const { x, y } = group.Meta;
  return group.elementIds.length > 0
    ? { maxX, maxY, minW, minH }
    : {
        maxX: x,
        maxY: y,
        minW: ELEMENT_INSTANCE_WIDTH,
        minH: ELEMENT_INSTANCE_WIDTH,
      };
}

export function calcResizeByDirection(
  resizeDir: string,
  dx: number,
  dy: number,
  x: number,
  y: number,
  width: number,
  height: number,
  maxX: number,
  maxY: number,
  minW: number,
  minH: number,
): Partial<ResizeDelta> {
  switch (resizeDir) {
    case 'n':
      return {
        y: Math.min(y + dy, maxY),
        height: height - Math.min(dy, maxY - y),
      };
    case 's':
      return {
        height: Math.max(height + dy, maxY - y + minH),
      };
    case 'e':
      return {
        width: Math.max(width + dx, maxX - x + minW),
      };
    case 'w':
      return {
        x: Math.min(x + dx, maxX),
        width: width - Math.min(dx, maxX - x),
      };
    case 'ne':
      return {
        y: Math.min(y + dy, maxY),
        height: height - Math.min(dy, maxY - y),
        width: Math.max(width + dx, maxX - x + minW),
      };
    case 'nw':
      return {
        x: Math.min(x + dx, maxX),
        y: Math.min(y + dy, maxY),
        width: width - Math.min(dx, maxX - x),
        height: height - Math.min(dy, maxY - y),
      };
    case 'se':
      return {
        height: Math.max(height + dy, maxY - y + minH),
        width: Math.max(width + dx, maxX - x + minW),
      };
    case 'sw':
      return {
        x: Math.min(x + dx, maxX),
        width: width - Math.min(dx, maxX - x),
        height: Math.max(height + dy, maxY - y + minH),
      };
    default:
      throw new Error('[calcResizeByDirection]: resize direction does not exist');
  }
}
