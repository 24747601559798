import React from 'react';

import InlineHelp from 'common/ui/components/InlineHelp/InlineHelp';

const factorNameHelp = (
  <InlineHelp heading="Factor Name">
    <p>This is the name of the variable that you want to study as part of your design.</p>
    <p>
      In Synthace this is often the name of a specific liquid within a mixture whose
      effects on your system you want to better understand. During data analysis after
      running your experiment this is the name that will identify this specific factor
      during modeling.
    </p>
  </InlineHelp>
);
const customCategoricalFactorNameHelp = (
  <InlineHelp heading="Factor Name">
    <p>
      The name for this categorical choice should indicate why these choices have been
      grouped.
    </p>
    <p>
      For example, you may call your category “Carbon Source”, and define factors called
      Glucose, Glycerol or Galactose, with each optionally having its own concentration.
      In any run in your design you will either get Glucose, Glycerol or Galactose at its
      corresponding concentration if one has been specified.
    </p>
  </InlineHelp>
);
const factorTypeHelp = (
  <InlineHelp heading="Factor Type">
    <p>Choose the type of your factor from Design, Derived or Quasi-Replicate.</p>
    <p>
      A <b>Design Factor</b> is one that will be considered in the calculation of your
      final DOE design. For example, the concentration of glucose added to your media.
    </p>
    <p>
      A <b>Derived Factor</b> is one that will be calculated from or paired to one or more
      of your Custom Factors. For example, the numerical pH value mapped to a specific
      liquid buffer to be used, or the concentration of one factor should always be a
      certain ratio greater than the concentration of another factor in your design.
    </p>
    <p>
      A <b>Quasi-Replicate</b> is a factor whose levels are not included as part of your
      design calculation but decide how many times your entire design should be replicated
      for each of its factors levels. For example, you might want to replicate your entire
      design three times based on a factor “Cell Types”, of which there are three
      different types (levels).
    </p>
  </InlineHelp>
);
const factorLevelsHelp = (
  <InlineHelp heading="Factor Levels">
    <p>The levels or level boundaries you want to investigate for the named factor.</p>
    <p>
      For space ﬁlling designs specifying two levels for a numeric factor will set the
      range from which to sample levels between. The number of levels between your upper
      and lower range will be equal to the number of runs you choose to investigate in
      your design.
    </p>
    <p>
      Specifying more than two levels for a numeric factor in an Optimal design will treat
      the middle level values as midpoints and provide power analysis for quadratic
      eﬀects, or curvature, in the design space.
    </p>
  </InlineHelp>
);
const categoricalFactorLevelsHelp = (
  <InlineHelp heading="Factor Levels">
    <p>The levels you want to investigate for the named factor.</p>
  </InlineHelp>
);
const simpleMutualExclusionFactorLevelsHelp = (
  <InlineHelp heading="Factor Levels">
    <p>The levels you want to investigate for the named factor.</p>
    <p>Each level has an associated quantity.</p>
  </InlineHelp>
);
const hardToChangeHelp = (
  <InlineHelp heading="Hard to Change">
    <p>
      A hard-to-change (HTC) factor is a factor that is diﬀicult to randomize completely
      because of time, control or cost constraints. For example, temperature is a common
      hard-to-change factor because adjusting temperature often requires separate
      incubators where blocks of runs are tested simultaneously across microtiter plates
      in the separate incubators.
    </p>
    <p>
      Specifying that a factor is hard to change will ensure your design adds replication
      into your different hard to change blocks such that the effect of temperature for
      example can be discerned from the effect of splitting your runs onto different
      plates.
    </p>
  </InlineHelp>
);
const simpleMutualExclusionNameHelp = (
  <InlineHelp heading="Name">
    <p>
      The name for this categorical choice should indicate why these choices have been
      grouped.
    </p>
    <p>
      For example, you may call your category “Carbon Source”, and define factors called
      Glucose, Glycerol or Galactose with each having its own concentration. In any run in
      your design you will either get Glucose, Glycerol or Galactose at its corresponding
      concentration.
    </p>
  </InlineHelp>
);
const addQuantitiesHelp = (
  <InlineHelp heading="Add a quantity to each level">
    <p>
      This option allows you to assign each categorical level an accompanying quantity.
    </p>
    <p>
      For example if adding a liquid such as a buffer this could be used to express how
      much of a given buffer type is to be added.
    </p>
  </InlineHelp>
);
const samplingHelp = (
  <InlineHelp heading="Sampling">
    <p>
      For Space filling designs, conﬁgure whether your numerical factor levels should be
      sampled across the range or as discrete values.
    </p>
    <p>
      Sampling across the range means that this factor will have as many levels as there
      are runs in your design.
    </p>
    <p>
      Sampling discreetly means that the runs from your design will only have this factor
      at one of the levels defined above.
    </p>
  </InlineHelp>
);

const numberOfZerosHelp = (
  <InlineHelp heading="Number of zeros to include">
    <p>
      For Space filling designs, conﬁgure whether your design should also include a
      specified number of runs that set this factor level to 0, testing the absence of
      this factor.
    </p>
    <p>
      It is suggested that at least three runs in your design should have zero set points
      if you want to test the absence of this factor.
    </p>
  </InlineHelp>
);

const derivingExpressionHelp = (
  <InlineHelp heading="Equation">
    <p>
      This is an equation that transforms your factors into a new calculated (i.e.
      derived) factor.
    </p>
    <p>
      <ul>
        <li>
          Click on the factors listed below to add them to the equation. Do not alter the
          text within the &#123; &#125; braces
        </li>
        <li>String values should be added with quotation marks e.g. &quot;BSA&quot;</li>
        <li>
          Operators supported for numeric values include: +, -, /, *, **, ==, !=, &lt;,
          &gt;
        </li>
        <li>Operators supported for string values include: ==, !=</li>
      </ul>
    </p>
  </InlineHelp>
);

const sourceFactorHelp = (
  <InlineHelp heading="Derive From Factor">
    <p>
      This is the source factor from which this factor&apos;s value should be derived.
    </p>
    <p>May be any non-derived numeric or categorical factor.</p>
  </InlineHelp>
);

const derivedLevelsHelp = (
  <InlineHelp heading="Levels">
    <p>
      Levels of the derived-from factor (a.k.a &quot;source factor&quot;) have to be
      assigned to one or more levels of the derived factor.
    </p>
    <p>All levels of the derived-from factor must be assigned.</p>
  </InlineHelp>
);

const quasiReplicateHelp = (
  <InlineHelp heading="Quasi-Replicate">
    <p>
      A <b>Quasi-Replicate</b> is a factor whose levels are not included as part of your
      design calculation but decide how many times your entire design should be replicated
      for each of its factors levels. For example, you might want to replicate your entire
      design three times based on a factor “Cell Types”, of which there are three
      different types (levels).
    </p>
  </InlineHelp>
);

const constantKindHelp = (
  <InlineHelp heading="Kind">
    <p>
      A <b>Numerical</b> constant will have a value that can be represented as a scalable
      number e.g. Concentration or Volume.
    </p>
    <p>
      A <b>Categorical</b> constant will have a value that describes a category or group.
    </p>
  </InlineHelp>
);

export default {
  factorNameHelp,
  customCategoricalFactorNameHelp,
  factorTypeHelp,
  factorLevelsHelp,
  categoricalFactorLevelsHelp,
  simpleMutualExclusionFactorLevelsHelp,
  hardToChangeHelp,
  simpleMutualExclusionNameHelp,
  addQuantitiesHelp,
  samplingHelp,
  numberOfZerosHelp,
  derivingExpressionHelp,
  sourceFactorHelp,
  derivedLevelsHelp,
  quasiReplicateHelp,
  constantKindHelp,
};
