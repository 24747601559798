import React, { useCallback, useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Warning from '@mui/icons-material/Warning';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardHeader, { cardHeaderClasses } from '@mui/material/CardHeader';
import Collapse from '@mui/material/Collapse';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { CSSObject, styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

import { MANUFACTURER_IMAGES } from 'client/app/components/Parameters/PlateType/plateConstants';
import PlateDrawing from 'client/app/components/Parameters/PlateType/PlateDrawing';
import { HasPermission } from 'client/app/components/Permissions';
import UILeftRight from 'client/app/components/UILeftRight';
import sanitizeLink from 'client/app/lib/sanitizeLink';
import { getVolumeString } from 'common/lib/format';
import { PlateType } from 'common/types/plateType';
import Colors from 'common/ui/Colors';
import Button from 'common/ui/components/Button';
import Tooltip from 'common/ui/components/Tooltip';
import { usePopover } from 'common/ui/hooks/usePopover';
import { BookAndBulbIcon } from 'common/ui/icons/BookAndBulbIcon';

const additionalInfo: [
  'accessory' | 'catalogNumber' | 'type' | 'manufacturer',
  string,
][] = [
  ['accessory', 'Accessory'],
  ['catalogNumber', 'Number'],
  ['type', 'Parameter Value'],
  ['manufacturer', 'Manufacturer'],
];

type Props = {
  onSelect?: (plateId: string) => void;
  onDoubleSelect?: (plateId: string) => void;
  accessibleDeviceCompatibilityMessage: string | null;
  selected?: boolean;
  onDeletePlateType?: (plateName: string, plateType: string) => void;
  onCopyPlateType?: (plateId: string) => void;
  onUpdatePlateType?: (plateId: string) => void;
  plate: PlateType;
};

export const PlateCard = React.memo(function PlateCard(props: Props) {
  // get the type from props, which is a PlateType, when selecting a card, as the type is the field that needs to be inserted in workflows
  const {
    accessibleDeviceCompatibilityMessage,
    onCopyPlateType,
    onDeletePlateType,
    onDoubleSelect,
    onSelect,
    onUpdatePlateType,
    plate,
    selected,
  } = props;

  const isSelectable = !!onSelect;

  const actionArea = (
    <>
      <PlateHeader
        accessibleDeviceCompatibilityMessage={accessibleDeviceCompatibilityMessage}
        contentSource={plate.contentSource}
        description={plate.description}
        editable={plate.editable}
        id={plate.id}
        manufacturer={plate.manufacturer}
        name={plate.name}
        onCopyPlateType={onCopyPlateType}
        onUpdatePlateType={onUpdatePlateType}
      />
      <PlateContent plate={plate} />
    </>
  );

  return (
    <StyledCard raised={selected}>
      <Inner selected={selected}>
        {isSelectable ? (
          <StyledCardActionArea
            disableRipple
            onClick={e => {
              switch (e.detail) {
                case 1:
                  onSelect(plate.type);
                  break;
                case 2:
                  onDoubleSelect?.(plate.type);
                  break;
              }
            }}
          >
            {actionArea}
          </StyledCardActionArea>
        ) : (
          <StyledNonInteractiveCardActionArea>
            {actionArea}
          </StyledNonInteractiveCardActionArea>
        )}
        <PlateFooter
          onDeletePlateType={onDeletePlateType}
          accessory={plate.accessory}
          catalogNumber={plate.catalogNumber}
          type={plate.type}
          manufacturer={plate.manufacturer}
          editable={plate.editable}
          name={plate.name}
          catalogUrl={plate.catalogUrl}
        />
      </Inner>
    </StyledCard>
  );
});

type PlateFooterProps = Pick<Props, 'onDeletePlateType'> &
  Pick<
    PlateType,
    | 'accessory'
    | 'catalogNumber'
    | 'type'
    | 'manufacturer'
    | 'editable'
    | 'name'
    | 'catalogUrl'
  >;

function PlateFooter(props: PlateFooterProps) {
  const { catalogUrl, onDeletePlateType, editable, name, type } = props;
  const [collapsed, setCollapsed] = useState(true);
  const toggle = useCallback(() => setCollapsed(collapsed => !collapsed), [setCollapsed]);
  const onClickDelete = useCallback(
    () => onDeletePlateType?.(name, type),
    [name, onDeletePlateType, type],
  );

  return (
    <PlateFooterContainer>
      <Divider />
      <UILeftRight>
        <>
          {onDeletePlateType && editable && (
            <HasPermission
              permission="delete:plateTypes"
              renderItem={hasPermission => {
                return (
                  <Button
                    variant="tertiary"
                    onClick={onClickDelete}
                    disabled={!hasPermission}
                  >
                    Delete
                  </Button>
                );
              }}
              toolTipCopy="Please contact an admin in your organisation to modify"
            />
          )}
          {catalogUrl ? (
            <CatalogLink
              target="_blank"
              rel="noopener noreferrer"
              href={sanitizeLink(catalogUrl)}
            >
              <Button variant="tertiary">Visit Catalog</Button>
            </CatalogLink>
          ) : null}
        </>
        <div>
          {collapsed ? <ExpandMore onClick={toggle} /> : <ExpandLess onClick={toggle} />}
        </div>
      </UILeftRight>
      <Collapse in={!collapsed}>
        {additionalInfo.map(([key, label]) => {
          const value = props[key];
          return <InfoLine key={key} label={label} value={value} />;
        })}
      </Collapse>
    </PlateFooterContainer>
  );
}

function PlateHeaderAction(props: {
  id: string;
  onCopyPlateType: (id: string) => void;
  onUpdatePlateType: (id: string) => void;
  editable: boolean;
}) {
  const { popoverAnchorElement, isPopoverOpen, onShowPopover, onHidePopover } =
    usePopover();

  const { onCopyPlateType, onUpdatePlateType, id, editable } = props;
  const onClickCopy = () => onCopyPlateType(id);
  const onClickUpdate = () => onUpdatePlateType(id);

  return (
    <>
      <IconButton onClick={onShowPopover} size="large">
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={popoverAnchorElement}
        keepMounted
        open={isPopoverOpen}
        onClose={onHidePopover}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={onClickCopy}>Copy to custom plate</MenuItem>
        <HasPermission
          permission="update:plateTypes"
          renderItem={hasPermission => {
            return (
              <MenuItem onClick={onClickUpdate} disabled={!hasPermission || !editable}>
                Edit plate type
              </MenuItem>
            );
          }}
          toolTipCopy="Please contact an admin in your organisation to modify"
        />
      </Menu>
    </>
  );
}

type PlateHeaderProps = Pick<
  Props,
  'onCopyPlateType' | 'onUpdatePlateType' | 'accessibleDeviceCompatibilityMessage'
> &
  Pick<
    PlateType,
    'manufacturer' | 'contentSource' | 'description' | 'name' | 'id' | 'editable'
  >;

function PlateHeader(props: PlateHeaderProps) {
  const subheaderContent =
    props.contentSource === 'EXAMPLE' ? (
      <ExampleIconAndDescription>
        <div>{props.description}</div>
        <Tooltip title="Example Plate" placement="bottom">
          <IconDiv>
            <BookAndBulbIcon />
          </IconDiv>
        </Tooltip>
      </ExampleIconAndDescription>
    ) : (
      props.description
    );

  return (
    <StyledCardHeader
      title={props.name}
      subheader={<>{subheaderContent}</>}
      avatar={<ManufacturerIcon manufacturer={props.manufacturer} />}
      action={
        <>
          {props.accessibleDeviceCompatibilityMessage ? (
            <StyledTooltip title={props.accessibleDeviceCompatibilityMessage}>
              <Warning color="warning" />
            </StyledTooltip>
          ) : null}
          {props.onCopyPlateType && props.onUpdatePlateType ? (
            <PlateHeaderAction
              onCopyPlateType={props.onCopyPlateType}
              onUpdatePlateType={props.onUpdatePlateType}
              id={props.id}
              editable={props.editable}
            />
          ) : null}
        </>
      }
    />
  );
}

type ManufacturerIconProps = Pick<PlateType, 'manufacturer'>;

function ManufacturerIcon(props: ManufacturerIconProps) {
  if (!MANUFACTURER_IMAGES[props.manufacturer ?? '']) {
    return <div style={{ width: '0px', height: '40px' }} />;
  }
  return (
    <img
      src={MANUFACTURER_IMAGES[props.manufacturer ?? '']}
      style={{ width: '40px', height: '40px' }}
    />
  );
}

function PlateContent({ plate }: { plate: PlateType }) {
  return (
    <Content>
      <PlateDrawing plate={plate} />
      <WellVolume
        max={plate.wellShape.volumeOverrideUl}
        min={plate.defaultResidualVolume}
      />
    </Content>
  );
}

function InfoLine({ label, value }: { label: string; value?: string | null }) {
  return (
    <div>
      <UILeftRight>
        <Typography>{label}</Typography>
        <Typography>{value}</Typography>
      </UILeftRight>
    </div>
  );
}

function WellVolume({ max, min }: { max: number; min: number }) {
  return (
    <WellVolumeContainer>
      <div>{getVolumeString(max)}</div>
      <div>{getVolumeString(min)}</div>
    </WellVolumeContainer>
  );
}

const StyledCardHeader = styled(CardHeader)({
  [`& .${cardHeaderClasses.action}`]: {
    display: 'flex',
  },
  width: '100%',
});

const StyledTooltip = styled(Tooltip)(({ theme }) => ({ margin: theme.spacing(4) }));

const StyledCard = styled(Card)({ alignSelf: 'center' });

const Content = styled('div')({
  flexBasis: '160px',
  overflow: 'hidden',
  padding: '5px',
  display: 'grid',
  gridTemplateColumns: '4fr 1fr',
  gridTemplateRows: '100%',
});

const Inner = styled('div', { shouldForwardProp: propName => propName !== 'selected' })<{
  selected?: boolean;
}>(({ selected }) => ({
  display: 'flex',
  flexDirection: 'column',
  border: selected ? `1px solid ${Colors.BLUE_50}` : `1px solid transparent`,
}));

const WellVolumeContainer = styled('div')({
  width: '60px',
  height: '60px',
  justifySelf: 'flex-end',
  justifyContent: 'space-around',
  display: 'flex',
  flexDirection: 'column',
  padding: '2px',
  '& div:first-of-type': {
    color: Colors.GREEN,
  },
  '& div:last-child': {
    color: Colors.RED,
  },
  alignItems: 'center',
  border: `3px solid ${Colors.GREY_40}`,
});

const CatalogLink = styled('a')({ textDecoration: 'none' });

const IconDiv = styled('div')(({ theme }) => ({
  display: 'inline-block',
  marginLeft: theme.spacing(1),
}));

const ExampleIconAndDescription = styled('div')({
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
});

const CardActionAreaStyles: CSSObject = {
  display: 'flex',
  flexDirection: 'column',
  padding: '5px 5px 0 5px',
};
const StyledCardActionArea = styled(CardActionArea)(CardActionAreaStyles);
const StyledNonInteractiveCardActionArea = styled('div')(CardActionAreaStyles);

const PlateFooterContainer = styled('div')({
  padding: '0 5px 5px',
});
