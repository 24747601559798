import React from 'react';

import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import { styled } from '@mui/material/styles';

import PolicyLibraryApp from 'client/app/apps/policy-library/PolicyLibraryApp.new';
import CANCEL_CHOICE from 'client/app/components/Parameters/cancel';
import DetailsView from 'client/app/components/Parameters/Policy/components/DetailsView';
import {
  DetailsViewActions,
  ListViewActions,
} from 'client/app/components/Parameters/Policy/components/DialogActions';
import {
  DetailsViewTitle,
  ListViewTitle,
} from 'client/app/components/Parameters/Policy/components/DialogTitle';
import usePolicyHandlers, {
  Views,
} from 'client/app/components/Parameters/Policy/hooks/usePolicyHandlers.hook';
import useShowListOnOpen from 'client/app/components/Parameters/Policy/hooks/useShowListOnOpen.hook';
import { LiquidPolicy } from 'client/app/gql';
import Colors from 'common/ui/Colors';
import ComplexActionDialog from 'common/ui/components/Dialog/ComplexActionDialog';
import { DialogProps } from 'common/ui/hooks/useDialog';

type Props = {
  value: string;
} & DialogProps<string | undefined | typeof CANCEL_CHOICE>;

export default function PolicyDialogNew({ value, isOpen, onClose }: Props) {
  const { policy, view, dialog, policyParameterValue } = usePolicyHandlers({
    value,
    onClose,
  });

  useShowListOnOpen({ isOpen, showList: view.showList });

  const selectMessage = policyParameterValue ? 'Select Policy' : 'Select None';

  let title: JSX.Element;
  let content: JSX.Element;
  let actions: JSX.Element;

  switch (view.active) {
    case Views.LIST: {
      title = <ListViewTitle />;
      content = (
        <PolicyLibraryApp
          selectedPolicyName={policyParameterValue}
          onCardClick={policy.toggle}
          onCardDoubleClick={policy.selectAndClose}
          onViewDetails={view.showDetails}
        />
      );
      actions = (
        <ListViewActions
          selectMessage={selectMessage}
          onSelectButtonClick={dialog.submit}
        />
      );
      break;
    }
    case Views.DETAILS: {
      title = (
        <DetailsViewTitle
          name={policy.name}
          author={policy.createdBy?.displayName}
          createDate={policy.createdAt}
        />
      );
      content = <DetailsView policy={policy as LiquidPolicy} />;
      actions = (
        <DetailsViewActions
          selectMessage={selectMessage}
          onSelectButtonClick={dialog.submit}
        />
      );
      break;
    }
    default:
      throw new Error('Unknown view');
  }

  return (
    <ComplexActionDialog
      title={title}
      onClose={dialog.cancel}
      onBackClick={dialog.goBack}
      isOpen={isOpen}
      showCloseButton
      fullHeight
      content={<DialogContent>{content}</DialogContent>}
      dialogActions={<DialogActions>{actions}</DialogActions>}
    />
  );
}

//#region Styles

const DialogContent = styled(MuiDialogContent)(() => ({
  display: 'flex',
  padding: 0,
  backgroundColor: Colors.GREY_5,
}));

const DialogActions = styled(MuiDialogActions)(({ theme }) => ({
  justifyContent: 'space-between',
  padding: theme.spacing(4, 5),
  backgroundColor: Colors.GREY_5,
}));

//#endregion
